import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReplaceButton from './ReplaceButton';

const CompanyItem = ({
  company, decorator, nameWidth, onReplaceClick,
}) => {
  const handleItemClick = (e) => {
    if (company.showReplaceButton) {
      e.preventDefault();
      e.stopPropagation();
      onReplaceClick(company);
    }
    return false;
  };
  return (
    <div
      key={company.value}
      className={cx('tt-suggestion', { disabled: company.added, 'has-replace-btn': company.showReplaceButton })}
      onClick={e => handleItemClick(e)}
    >
      <div className="category-item publisher-item company-item">
        <div className="d-flex align-items-center flex-grow text-truncate">
          <h4 className="d-flex align-items-baseline m-b-0" style={{ width: nameWidth, flexGrow: 0 }}>
            <i className="store-icon hicon-company mr-xs" />
            <span className="text-black text-truncate mr-xxxs publisher-item-name">{company.name}</span>
            <span className="text-xxxs text-quiet text-uppercase publisher-item-description">
              {company.stock_exchange && `${company.stock_exchange}: `}
              {company.ticker && company.ticker}
              {company.desc && company.desc}
            </span>
            {company.showReplaceButton && <ReplaceButton />}
          </h4>
          {decorator}
        </div>
      </div>
    </div>
  );
};

CompanyItem.propTypes = {
  company: PropTypes.object.isRequired,
  decorator: PropTypes.element,
  nameWidth: PropTypes.string,
  onReplaceClick: PropTypes.func,
};
CompanyItem.defaultProps = {
  nameWidth: '100%',
};

export default CompanyItem;
