import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import NumberHelpers from 'number_helpers';
import { isNil, pick, some } from 'lodash';
import helpers from '../../utils/helpers';

import AppItem from './AppItem';
import { downloadsRender, priceAbbreviationSettings } from './shared';

const fields = ['dls_30d', 'primary_category_id', 'price'];

const AppItemWithPerformance = ({ app, onReplaceClick }) => {
  const hasPerformanceData = some(pick(app, fields), item => !isNil(item));

  const appDecorator = hasPerformanceData ? (
    <Fragment>
      {downloadsRender(app.dls_30d)}
      {app.primary_category_id && (
        <div className="mx-auto px-xs" style={{ width: 190 }}>
          {helpers.appCategoriesNames(app.primary_category_id, app.category_ids, app.store)}
        </div>)
      }
      {app.price !== undefined &&
        <div className="ml-auto pl-xs text-right price" style={{ width: 60 }}>
          {[0, null, 'Free'].includes(app.price) ?
            'Free' :
            `${NumberHelpers.number_to_currency(app.price / 100, priceAbbreviationSettings)}`
          }
        </div>
      }
    </Fragment>
  ) : null;

  return (
    <AppItem
      app={app}
      decorator={appDecorator}
      nameWidth={hasPerformanceData ? 175 : '100%'}
      onReplaceClick={onReplaceClick}
    />
  );
};

AppItemWithPerformance.propTypes = {
  app: PropTypes.shape({
    ref_no: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    store: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon_url: PropTypes.string.isRequired,
    icon_url_2x: PropTypes.string,
    value: PropTypes.string.isRequired,
    publisher_name: PropTypes.string,
    dls_30d: PropTypes.number,
    primary_category_id: PropTypes.number,
    category_ids: PropTypes.arrayOf(PropTypes.number),
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    added: PropTypes.bool,
  }).isRequired,
  onReplaceClick: PropTypes.func,
};

export default AppItemWithPerformance;

