import { isEmpty, keys, first, last } from 'lodash';
import { format } from '../date';

export const seriesLineWidth = 1.5;

export const getTickInterval = (baseSeries = {}, controlState) => {
  const {
    granularity, secondaryMetricName, primaryChartType, secondaryChartType,
  } = controlState;

  const isLineTypeOnly = () => {
    const lineTypes = ['spline', 'areaspline'];

    if (!secondaryMetricName) return lineTypes.includes(primaryChartType);
    return lineTypes.includes(primaryChartType) && lineTypes.includes(secondaryChartType);
  };

  if (['monthly', 'quarterly'].includes(granularity) && isLineTypeOnly()) {
    if (!isEmpty(baseSeries)) {
      const firstSeriesKey = keys(baseSeries)[0];
      const [firstPoint = [], secondPoint = []] = baseSeries[firstSeriesKey];

      if (firstPoint[0] && secondPoint[0]) return secondPoint[0] - firstPoint[0];
    }
  }

  return undefined;
};
