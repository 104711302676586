import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'rc-tooltip'; // https://github.com/react-component/tooltip

// According to https://github.com/react-component/tooltip#important-note, we wrap children in HTML
// node .tooltip-node-container to be able to use Tooltip with a custom component as a child

const Tooltip = ({
  id,
  disabled,
  children,
  content,
  placement,
  overlayStyle,
  overlayClassName,
  colorVariant,
  visible,
  mouseEnterDelay,
  onVisibleChange,
  wrapChildrenInHtmlNode = false,
}) => {
  if (disabled || !content) {
    return children;
  }

  return (
    <ReactTooltip
      id={id}
      placement={placement}
      overlay={content}
      overlayStyle={overlayStyle}
      destroyTooltipOnHide
      mouseEnterDelay={mouseEnterDelay}
      overlayClassName={`${overlayClassName} ${colorVariant !== 'dark' ? colorVariant : ''}`}
      onVisibleChange={onVisibleChange}
      {...(visible ? { visible } : {})}
    >
      {wrapChildrenInHtmlNode
        ? <div className="tooltip-node-container">{children}</div>
        : children
      }
    </ReactTooltip>
  );
};

Tooltip.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  content: PropTypes.node,
  overlayStyle: PropTypes.shape({}),
  children: PropTypes.element.isRequired,
  overlayClassName: PropTypes.string,
  colorVariant: PropTypes.oneOf(['dark', 'light', 'blue']),
  visible: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  mouseEnterDelay: PropTypes.number,
  wrapChildrenInHtmlNode: PropTypes.bool,
};

Tooltip.defaultProps = {
  id: null,
  disabled: false,
  content: null,
  placement: 'topLeft',
  overlayStyle: {},
  overlayClassName: '',
  colorVariant: 'dark',
  mouseEnterDelay: 0.5,
};

export default Tooltip;
