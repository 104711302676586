import React from 'react';
import T from 'prop-types';
import cx from 'classnames';

const ContainerItem = ({ children, className }) => (
  <div className={cx('tt-suggestion', className)}>
    {children}
  </div>
);

ContainerItem.propTypes = {
  children: T.node.isRequired,
  className: T.string,
};

export default ContainerItem;
