import React, { Fragment } from 'react';
import T from 'prop-types';
import cx from 'classnames';
import { reduce } from 'lodash';

import AppIcon from '../AppIcon';
import Tooltip from '../Tooltip';
import { format } from '../../utils/date';
import { assetMapping } from '../../utils/helpers';

const countersStringify = (counters) => {
  const labelByEntity = {
    apps: counters.apps > 1 ? 'Apps' : 'App',
    publishers: counters.publishers > 1 ? 'Publishers' : 'Publisher',
    companies: counters.companies > 1 ? 'Companies' : 'Company',
  };

  return reduce(counters, (result, counter, entity) => {
    if (!counter) return result;
    return result.concat(`${counter} ${labelByEntity[entity]}`);
  }, []).join(' · ');
};

const MarketItem = ({ market }) => {
  const itemTooltipContent = (
    <Fragment>
      <h6 className="market-name text-truncated">{market.name}</h6>
      <div className="market-info">
        <p>Created {format(market.created_date || market.created_at)}</p>
        <p>
          {countersStringify({
            apps: market.apps_entities_count,
            publishers: market.publishers_count,
            companies: market.companies_count,
          })}
        </p>
      </div>
    </Fragment>
  );

  const marketApps = (market.apps_icons || [])
    .filter(entity => assetMapping.apps.includes(entity.kind));

  return (
    <Tooltip
      overlayClassName="search-typeahead-market-item-tooltip"
      placement="topRight"
      content={itemTooltipContent}
    >
      <div className={cx('tt-suggestion', { disabled: market.added })}>
        <div className="category-item market-item">
          <div className="market-item__name text-truncate">
            {market.name}
          </div>
          <div className="market-item__apps">
            {marketApps.map((app, index) => (
              <AppIcon
                key={app.ref_no || index}
                alt={app.name}
                url={app.icon_url}
                url2x={app.icon_url}
                size={24}
                store={app.store}
                withBorder
              />
            ))}
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

MarketItem.propTypes = {
  market: T.shape({
    name: T.string.isRequired,
    store: T.string,
    created_date: T.string,
    created_at: T.string,
    added: T.bool,
    apps_entities_count: T.number,
    publishers_count: T.number,
    companies_count: T.number,
    apps_icons: T.array,
  }).isRequired,
};

export default MarketItem;
