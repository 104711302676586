import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import cx from 'classnames';

import SearchTypeahead from './SearchTypeahead';
import SwitchButtons from './SwitchButtons';
import LoadingSpinner from './LoadingSpinner';
import { getAssetLocation } from '../utils/helpers';
import { numberToHumanSummary } from '../utils/formatter';

const assetsLimit = 20;

const mappedAssets = (nameForKeywords = 'Keywords') => ({
  apps: {
    value: 'apps',
    iconClass: 'hicon-apps text-xs',
    prefix: 'Apps',
  },
  companies: {
    value: 'companies',
    iconClass: 'hicon-company text-xs',
    prefix: 'Companies',
  },
  publishers: {
    value: 'publishers',
    iconClass: 'hicon-publisher text-xs',
    prefix: 'Publishers',
  },
  sdks: {
    value: 'sdks',
    iconClass: 'hicon-sdk-box-lite text-xs',
    prefix: 'SDKs',
  },
  keywords: {
    value: 'keywords',
    iconClass: 'hicon-keywords-dissable text-xs',
    prefix: nameForKeywords,
  },
  markets: {
    value: 'markets',
    iconClass: 'hicon-market text-xs',
    prefix: 'In My Markets',
  },
});

class SearchComponent extends PureComponent {
  static propTypes = {
    availableAssets: PropTypes.array.isRequired,
    availableSearchBy: PropTypes.bool,
    userPresent: PropTypes.bool.isRequired,
    selectedEntities: PropTypes.array,
    onChange: PropTypes.func,
    requestOptions: PropTypes.object,
    itemSelected: PropTypes.func,
    onReplaceClick: PropTypes.func,
    className: PropTypes.string,
    minLength: PropTypes.number,
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    disableRecentResults: PropTypes.bool,
    disableSearchOnFocus: PropTypes.bool,
    showAdvancedSearchLink: PropTypes.bool,
    cancelRequestsAfterSelect: PropTypes.bool,
    disableOnEnterDown: PropTypes.bool,
    disabled: PropTypes.bool,
    onSearch: PropTypes.func,
    beforeSearch: PropTypes.func,
    decorateResultsOnFocus: PropTypes.func,
    filterSearchResults: PropTypes.func,
    defaultSelectedAsset: PropTypes.string,
    decorators: PropTypes.array,
    referrer: PropTypes.string,
    autoFocus: PropTypes.bool,
    open: PropTypes.bool,
    disabledOnError: PropTypes.bool,
    store: PropTypes.string,
    customMenuItems: PropTypes.func,
    renderCustomMenuFooter: PropTypes.func,
    onAssetChange: PropTypes.func,
    nameForKeywordsTab: PropTypes.string,
    AppItemProps: PropTypes.object,
    PublisherItemProps: PropTypes.object,
    KeywordItemProps: PropTypes.object,
    renderMenuItem: PropTypes.func,
    advancedRequestConfig: PropTypes.object,
    errorsMessages: PropTypes.object,
    reSearchFlag: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    customNoResultsMessage: PropTypes.func,
    onMenuToggle: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    referrer: '',
    minLength: 0,
    placeholder: 'Search',
    defaultSelectedAsset: 'apps',
    selectedEntities: [],
    disableRecentResults: true,
    disableSearchOnFocus: true,
    showAdvancedSearchLink: false,
    availableSearchBy: false,
    disableOnEnterDown: false,
    cancelRequestsAfterSelect: false,
    disabled: false,
    requestOptions: {},
    beforeSearch: null,
    decorators: [],
    autoFocus: false,
    onSearch: () => {},
  };

  state = {
    selectedAsset: this.props.defaultSelectedAsset,
    selectedSearchBy: 'name',
    query: '',
    resultByAssets: {},
  };

  viewAllLink = (term, by, assets) => `/search/view-all?${qs.stringify({ term, by, assets }, { arrayFormat: 'brackets' })}`;

  searchTypeaheadRef = () => this.searchTypeahead.typeaheadInstance();

  handleChange = ([selectedItem], query) => {
    if (!selectedItem) return;

    if (this.props.onChange) {
      this.props.onChange(selectedItem, query, this.searchTypeaheadRef);
      return;
    }

    window.location.assign(getAssetLocation(selectedItem));
  };

  handleSearch = (result, query) => {
    const { groupedResults } = result;
    const {
      selectedAsset,
    } = this.state;
    const {
      hasInfiniteScroll,
    } = this.props;
    const res = groupedResults[selectedAsset];
    this.setState({
      query,
      resultByAssets: groupedResults,
      total: res && res.totalCount,
      limit: assetsLimit,
    }, () => {
      this.props.onSearch(query, groupedResults, selectedAsset);
    });
  };

  handleEnterDown = (inputValue) => {
    const { selectedAsset, selectedSearchBy } = this.state;

    if (this.props.disableOnEnterDown) return;
    if (inputValue.length === 0) return;

    window.location.assign(this.viewAllLink(inputValue, selectedSearchBy, [selectedAsset]));
  };

  renderMenuHeader = () => {
    const {
      selectedAsset, query, selectedSearchBy, resultByAssets,
    } = this.state;

    const {
      availableAssets, availableSearchBy, disabledOnError, nameForKeywordsTab,
    } = this.props;

    const assetOptions = availableAssets.map((asset) => {
      const currAsset = mappedAssets(nameForKeywordsTab)[asset];
      const isLoaded = resultByAssets[asset] && !resultByAssets[asset].loading;
      const totalCount = resultByAssets[asset] && resultByAssets[asset].totalCount;
      return {
        ...currAsset,
        text: (
          <span>
            <span>{currAsset.prefix}</span>
            {!!query.length && (
              <span className="text-normal text-left ml-xxxs" style={{ minWidth: '2rem' }}>
                {isLoaded
                  ? <span className="search-total-count">({numberToHumanSummary(totalCount, { precision: 0 })})</span>
                  : <LoadingSpinner size="sm" className="text-black m-a-0" id={asset} />
                }
              </span>
            )}
          </span>
        ),
      };
    });

    const searchByOptions = [
      { text: 'By Name', value: 'name' },
      { text: 'By Keyword', value: 'keyword' },
    ];

    const handleAssetChange = (value) => {
      this.setState({ selectedAsset: value }, () => {
        if (this.props.onAssetChange) this.props.onAssetChange(value);
      });
      this.searchTypeaheadRef().focus();
    };

    const handleSearchByChange = (value) => {
      this.setState({ selectedSearchBy: value });
    };

    return (
      <div className="text-center bg-white">
        <div className="header-search-tabs tabs-container text-nowrap">
          <SwitchButtons
            btnClassName="d-flex flex-grow align-items-center btn-link text-xxs b-a-0"
            containerClassName="d-grid tabs b-b"
            containerStyles={{ gridTemplateColumns: `repeat(${availableAssets.length}, auto)` }}
            options={assetOptions}
            active={selectedAsset}
            disabled={disabledOnError}
            onChange={handleAssetChange}
          />
        </div>
        {availableSearchBy && selectedAsset === 'apps' &&
          <div
            className="text-quiet text-xxxs ml-xs mr-xs mb-xxs mt-xs search-by"
          >
            <SwitchButtons
              className="text-nowrap w-100"
              size="xs"
              btnStyle={{ width: '50%' }}
              containerStyles={{ maxWidth: '240px', width: '100%' }}
              btnClassName="btn btn-outline"
              options={searchByOptions}
              active={selectedSearchBy}
              onChange={handleSearchByChange}
            />
          </div>
        }
      </div>
    );
  };

  renderMenuFooter = (term, by, assets) => (
    <div className="category-item pos-rel tt-suggestion tt-selectable">
      <a
        href={this.viewAllLink(term, by, assets)}
        className="d-flex justify-content-center category-item sdk-item text-blue view-all-results-link"
      >
        <span>Advanced Search</span>
      </a>
    </div>
  );


  render() {
    const {
      query, selectedAsset, selectedSearchBy, limit, offset, total, resultByAssets,
    } = this.state;

    const {
      availableAssets, minLength, placeholder, className,
      filterSearchResults, onReplaceClick, itemSelected,
      showAdvancedSearchLink, userPresent,
      disabled, disableRecentResults, disableSearchOnFocus,
      cancelRequestsAfterSelect, decorateResultsOnFocus, beforeSearch,
      decorators, autoFocus, open, disabledOnError,
      store, requestOptions, AppItemProps, PublisherItemProps, KeywordItemProps,
      maxHeight, reSearchFlag, customMenuItems, renderCustomMenuFooter,
      renderMenuItem, advancedRequestConfig, errorsMessages, onMenuToggle, customNoResultsMessage,
    } = this.props;

    const isFullAssets = availableAssets.length === 5;
    const menuFooter = () => {
      if (showAdvancedSearchLink && query.length > 0) {
        return this.renderMenuFooter(query, selectedSearchBy, [selectedAsset]);
      }
      return null;
    };
    const customMenuFooter = () => {
      if (renderCustomMenuFooter && resultByAssets[selectedAsset] && resultByAssets[selectedAsset].data.length > 0) {
        return renderCustomMenuFooter(selectedAsset);
      }
      return null;
    };

    const scrollOptions = {
      limit, offset, total,
    };
    const defaultNoResultsMessage = () => {
      let message;
      if (customNoResultsMessage) {
        message = customNoResultsMessage({ query, selectedAsset });
      } else if (userPresent) {
        message = query.length > 0 ? 'No results found.' : 'No recent found.';
      } else {
        message = 'No results found.';
      }
      return message;
    };

    return (
      <SearchTypeahead
        ref={(ref) => {
          this.searchTypeahead = ref;
        }}
        assets={availableAssets}
        searchAsset={selectedAsset}
        searchBy={selectedSearchBy}
        itemSelected={itemSelected}
        minLength={minLength}
        requestOptions={requestOptions}
        advancedRequestConfig={advancedRequestConfig}
        menuHeader={this.renderMenuHeader()}
        renderCustomMenuItems={customMenuItems && customMenuItems(selectedAsset)}
        menuFooter={menuFooter()}
        customMenuFooter={customMenuFooter()}
        decorateItems={filterSearchResults}
        defaultNoResultsMessage={defaultNoResultsMessage()}
        disableSearchOnFocus={disableSearchOnFocus}
        disableRecentResults={disableRecentResults}
        cancelRequestsAfterSelect={cancelRequestsAfterSelect}
        disabled={disabled}
        className={cx('w-100 search-component', { 'full-list': isFullAssets }, className)}
        placeholder={placeholder}
        autoFocus={autoFocus}
        decorateResultsOnFocus={decorateResultsOnFocus}
        includesUnified
        clearButton
        open={open}
        decorators={decorators}
        disabledOnError={disabledOnError}
        store={store}
        AppItemProps={AppItemProps}
        PublisherItemProps={PublisherItemProps}
        KeywordItemProps={KeywordItemProps}
        maxHeight={maxHeight}
        renderMenuItem={renderMenuItem}
        errorsMessages={errorsMessages}
        reSearchFlag={reSearchFlag}
        onChange={this.handleChange}
        onSearchCallback={this.handleSearch}
        onBeforeSearchCallback={beforeSearch}
        onEnterDownCallback={this.handleEnterDown}
        onReplaceClick={onReplaceClick}
        onMenuToggle={onMenuToggle}
        {...scrollOptions}
      />
    );
  }
}

export default SearchComponent;
