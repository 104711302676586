import React from 'react';
import PropTypes from 'prop-types';


const ReportItem = ({ report }) => (
  <div key={report.id} className="tt-suggestion">
    <div className="category-item">
      <div className="d-flex aling-items-center text-truncate">
        <h3>{report.name}</h3>
      </div>
    </div>
  </div>
);

ReportItem.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

export default ReportItem;

