import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import AppIcon from '../AppIcon';
import ReplaceButton from './ReplaceButton';
import { storeName } from './shared';

const AppItem = ({
  app,
  appIconSize,
  decorator,
  nameWidth = '100%',
  showStoreId,
  className,
  onReplaceClick,
}) => {
  const handleItemClick = (e) => {
    if (app.showReplaceButton) {
      e.preventDefault();
      e.stopPropagation();
      onReplaceClick(app);
    }
    return false;
  };
  return (
    <div
      key={app.ref_no}
      className={cx('tt-suggestion', { disabled: app.added, 'has-replace-btn': app.showReplaceButton }, className)}
      onClick={e => handleItemClick(e)}
    >
      <div className="category-item app-item pos-rel">
        <div className="media-object flex-grow">
          <i className={`store-icon ${storeName(app.store)}`} />
          <div className="media-figure">
            <AppIcon
              alt={app.name}
              url={app.big_icon_url || app.icon_url}
              url2x={app.icon_url_2x}
              store={app.store}
              size={appIconSize}
            />
          </div>
          <div className="media-body d-flex" style={{ width: nameWidth }}>
            <div className="media-body__inner">
              <h4 className="text-truncate m-t-0 m-b-0">{app.value || app.name}</h4>
              {showStoreId ?
                <div className="show-store-id">
                  {`${app.publisher_name || ''}`}
                  <span className="mx-xxxs">&#x2022;</span>
                  {`Store ID: #${app.ref_no}`}
                </div> :
                <p className="text-truncate m-t-0 m-b-0">{app.publisher_name}</p>
              }
            </div>
            {app.showReplaceButton && <ReplaceButton />}
          </div>
          {decorator}
        </div>
      </div>
    </div>
  );
};

AppItem.propTypes = {
  app: PropTypes.shape({
    ref_no: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    store: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon_url: PropTypes.string,
    big_icon_url: PropTypes.string,
    icon_url_2x: PropTypes.string,
    value: PropTypes.string.isRequired,
    publisher_name: PropTypes.string,
    added: PropTypes.bool,
    showReplaceButton: PropTypes.bool,
    cross_store_app_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  appIconSize: PropTypes.number,
  decorator: PropTypes.element,
  nameWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showStoreId: PropTypes.bool,
  className: PropTypes.string,
  onReplaceClick: PropTypes.func,
};

export default AppItem;
