import React from 'react';
import T from 'prop-types';

const EntityConflictMessages = ({ enhancedEntities }) => {
  const conflicts = new Map();

  enhancedEntities.forEach((entity) => {
    (entity.conflicts || []).forEach((conflict) => {
      conflicts.set(conflict.type, conflict);
    });
  });

  const types = Array.from(conflicts.values());

  if (!types.length) return null;

  return (
    <div className="conflict-types">
      {types.map(({ type, message }) => (
        <div key={type} className="conflict-types__item">
          <span className={`conflict-types__item-icon ${message.icon}`} />
          <h6 className="conflict-types__item-title">{message.title}</h6>
          <p className="conflict-types__item-text">{message.text}</p>
        </div>
      ))}
    </div>
  );
};

EntityConflictMessages.propTypes = {
  enhancedEntities: T.arrayOf(T.shape({
    conflicts: T.arrayOf(T.shape({
      type: T.string,
      message: T.shape({
        icon: T.string,
        title: T.string,
        text: T.string,
      }),
    })),
  })).isRequired,
};

export default EntityConflictMessages;
