import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isNil, map, pick, some } from 'lodash';

import PublisherItem from './PublisherItem';
import AppIcon from '../AppIcon';
import { downloadsRender } from './shared';

const fields = ['dls_tier_30d', 'apps_count', 'icons'];

const PublisherItemWithPerformance = ({ publisher, onReplaceClick }) => {
  const hasPerformanceData = some(pick(publisher, fields), item => !isNil(item));

  const publisherDecorator = hasPerformanceData ? (
    <Fragment>
      {downloadsRender(publisher.dls_tier_30d)}
      <div className="mx-auto px-xs" style={{ width: 50 }}>
        {publisher.apps_count || 0}
      </div>
      <div className="app-icons-container d-flex" style={{ width: 180 }}>
        {map((publisher.icons || []), (icon, k) => (
          <div key={k} className="mr-xs d-inline-block flex-no-shrink" style={{ maxWidth: 24 }}>
            <AppIcon
              alt={k}
              url={icon['32x32']}
              url2x={icon['64x64']}
              size={24}
              store={publisher.store}
              withBorder
            />
          </div>
        ))}
      </div>
    </Fragment>
  ) : null;

  return (
    <PublisherItem
      publisher={publisher}
      decorator={publisherDecorator}
      nameWidth={hasPerformanceData ? 200 : '100%'}
      onReplaceClick={onReplaceClick}
    />
  );
};

PublisherItemWithPerformance.propTypes = {
  publisher: PropTypes.shape({
    value: PropTypes.string.isRequired,
    store: PropTypes.string,
    name: PropTypes.string.isRequired,
    added: PropTypes.bool,
    dls_tier_30d: PropTypes.number,
    apps_count: PropTypes.number,
    icons: PropTypes.object,
  }).isRequired,
  onReplaceClick: PropTypes.func,
};

export default PublisherItemWithPerformance;

