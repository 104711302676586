import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ModalContent from './ModalContent';

class Modal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    cardSize: PropTypes.oneOf(['xs', 'sm', 'lg', 'xl', 'xxl']),
    shouldCloseOnOverlayClick: PropTypes.bool,
    onClose: PropTypes.func,
    cardStyle: PropTypes.object,
    children: PropTypes.any,
    closeButtonIconClass: PropTypes.string,
    closeButtonVersionButton: PropTypes.bool,
    modalClassName: PropTypes.string,
    modalContentClass: PropTypes.string,
    modalId: PropTypes.string,
  };
  static defaultProps = {
    open: false,
    size: 'md',
    cardSize: 'sm',
    shouldCloseOnOverlayClick: false,
    onClose: () => {},
    cardStyle: {},
    closeButtonIconClass: '',
    modalClassName: '',
    modalContentClass: '',
    hideCloseButton: false,
  };
  componentDidMount() {
    document.addEventListener('keyup', this.onESCKeyup);
  }
  componentWillUnmount() {
    document.removeEventListener('keyup', this.onESCKeyup);
  }
  onESCKeyup = (e) => {
    e.stopPropagation();
    return e.keyCode === 27 && this.handleClose();
  }
  handleBackdropClick = () => {
    if (this.props.open === false) { return true; }
    return !this.props.shouldCloseOnOverlayClick;
  }
  handleClose = () => {
    if (!this.props.open) { return; }
    this.props.onClose();
    document.removeEventListener('keyup', this.onESCKeyup);
  }

  render() {
    const {
      size, cardSize, cardStyle, closeButtonIconClass,
      modalClassName, open, children, modalContentClass,
      shouldCloseOnOverlayClick, hideCloseButton, modalId,
      closeButtonVersionButton,
    } = this.props;
    return createPortal(
      (
        <div
          id={modalId}
          className={cx(`modal modal-${size}`, modalClassName, { in: open })}
          data-disable-close={`${!shouldCloseOnOverlayClick}`}
        >
          <ModalContent
            cardSize={cardSize}
            cardStyle={cardStyle}
            handleClose={this.handleClose}
            clickOutside={shouldCloseOnOverlayClick ? this.handleClose : () => {}}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            closeButtonIconClass={closeButtonIconClass}
            modalContentClass={modalContentClass}
            hideCloseButton={hideCloseButton}
            closeButtonVersionButton={closeButtonVersionButton}
          >
            {children}
          </ModalContent>
        </div>
      ),
      document.body,
    );
  }
}

export default Modal;
