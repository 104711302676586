import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import SearchComponent from './SearchComponent';
import FinanceMenuItems from './TypeaheadItems/FinanceMenuItems';
import FinanceMenuFooter from './TypeaheadItems/FinanceMenuFooter';
import { restrictedFinanceAssets } from '../utils/helpers';

const HeaderSearch = ({
  availableAssets,
  userPresent,
  isFinanceCustomer,
  className,
  minLength,
  placeholder,
  disabled,
}) => {
  const customMenuItems = useCallback((asset) => {
    if (isFinanceCustomer && restrictedFinanceAssets.includes(asset)) {
      return ({ items, selectedAsset, renderItem }) => (
        <FinanceMenuItems
          items={items}
          selectedAsset={selectedAsset}
          renderItem={renderItem}
        />
      );
    }

    return undefined;
  }, [isFinanceCustomer]);

  const customMenuFooter = useCallback((asset) => {
    if (isFinanceCustomer && restrictedFinanceAssets.includes(asset)) {
      return <FinanceMenuFooter asset={asset} />;
    }

    return undefined;
  }, [isFinanceCustomer]);

  return (
    <SearchComponent
      availableAssets={availableAssets}
      userPresent={userPresent}
      className={className}
      minLength={minLength}
      maxHeight={isFinanceCustomer ? '570px' : undefined}
      placeholder={disabled ? 'Search is not available' : placeholder}
      disableRecentResults={false}
      disableSearchOnFocus={false}
      availableSearchBy={false}
      cancelRequestsAfterSelect
      showAdvancedSearchLink={userPresent}
      disableOnEnterDown={!userPresent}
      disabled={disabled}
      customMenuItems={customMenuItems}
      renderCustomMenuFooter={customMenuFooter}
    />
  );
};

HeaderSearch.propTypes = {
  availableAssets: PropTypes.array.isRequired,
  userPresent: PropTypes.bool.isRequired,
  isFinanceCustomer: PropTypes.bool,
  className: PropTypes.string,
  minLength: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};
HeaderSearch.defaultProps = {
  className: 'header-search',
  minLength: 0,
  placeholder: 'Search',
  disabled: false,
};

export default HeaderSearch;
