import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Modal from './Modal';
import axios from '../configs/axios';
import Tooltip from './Tooltip';

const userFeatures = window.current_user_feature_requests || [];

export const ableToProceed = featureName => !userFeatures.includes(featureName);

const requestText = 'Feature was already requested';
class NewFeatureRequestButton extends Component {
  static propTypes = {
    featureName: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    closeOnOverlayClick: PropTypes.bool,
    buttonText: PropTypes.string,
    buttonStyles: PropTypes.object,
    modalClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    successText: PropTypes.string,
    tooltip: PropTypes.string,
    requestDisabled: PropTypes.bool,
    showAsLink: PropTypes.bool,
    showErrorInTooltip: PropTypes.bool,
    withoutModal: PropTypes.bool,
    alertTemplate: PropTypes.string,
    onRequestSuccess: PropTypes.func,
  };

  static defaultProps = {
    closeOnOverlayClick: true,
    buttonText: 'Request Upgrade',
    buttonStyles: {},
    buttonClassName: 'btn',
    successText: 'We\'ve let your Success Manager know you are interested in this feature',
    showAsLink: false,
  };

  static getDerivedStateFromProps(props) {
    if (props.requestDisabled) {
      return { requestButtonDisabled: props.requestDisabled };
    }
    return null;
  }

  state = {
    errorMessages: this.props.showErrorInTooltip && !ableToProceed(this.props.featureName)
      ? [requestText] : [],
    successModalOpened: false,
    requestButtonDisabled: !ableToProceed(this.props.featureName),
  }

  handleRequest = (e) => {
    const { featureName, alertTemplate, onRequestSuccess } = this.props;
    e.preventDefault();
    this.setState({ requestButtonDisabled: true }, () => (
      axios.post('/hubspot_submits/feature_request', {
        requested_feature: featureName,
        alert_template: alertTemplate,
      }).then(() => {
        this.setState({
          errorMessages: [],
          successModalOpened: true,
        }, () => {
          userFeatures.push(featureName);
          if (onRequestSuccess) onRequestSuccess();
          if (!ableToProceed(this.props.featureName)) {
            this.setState({ errorMessages: [requestText] });
          }
        });
      }).catch((error) => {
        this.setState({
          errorMessages: [error.response.data.error],
          successModalOpened: false,
        });
      })));
  };

  handleOnClose = () => {
    const { onClose } = this.props;
    if (onClose) { return onClose; }
    return this.setState({ successModalOpened: false });
  };

  buttonRender = () => {
    const {
      tooltip,
      buttonText,
      buttonStyles,
      buttonClassName,
      showAsLink,
      showErrorInTooltip,
    } = this.props;
    const {
      requestButtonDisabled,
      errorMessages,
    } = this.state;

    const buttonView = !showAsLink
      ? (
        <button
          className={cx(buttonClassName, { 'disabled cursor-not-allowed': requestButtonDisabled })}
          style={buttonStyles}
          disabled={requestButtonDisabled}
          onClick={this.handleRequest}
        >
          {buttonText}
        </button>
      ) : (
        <a
          className={cx(buttonClassName, { 'disabled cursor-not-allowed': requestButtonDisabled })}
          style={buttonStyles}
          onClick={this.handleRequest}
        >
          {buttonText}
        </a>);

    const displayBlock = showErrorInTooltip ? <div className={cx('d-inline-block', { 'cursor-not-allowed': requestButtonDisabled })} >{buttonView}</div> : buttonView;

    const errorsOptions = errorMessages.length > 0 &&
      <span>
        {errorMessages.map((error, i) => (
          <Fragment key={error}>
            {i > 0 && ', '}
            {error.toString()}
          </Fragment>
        ))}
      </span>;

    return (
      <Fragment>
        <Tooltip content={showErrorInTooltip ? errorsOptions : tooltip}>
          {displayBlock}
        </Tooltip>
        {!showErrorInTooltip && errorsOptions}
      </Fragment>
    );
  };

  render() {
    const {
      modalClassName,
      closeOnOverlayClick,
      successText,
      withoutModal,
    } = this.props;
    const {
      successModalOpened,
    } = this.state;

    return (
      successModalOpened && !withoutModal
        ? ReactDOM.createPortal(
          <Modal
            size="xs"
            cardSize="lg"
            open={successModalOpened}
            shouldCloseOnOverlayClick={closeOnOverlayClick}
            modalClassName={cx('modal-contrasted', !closeOnOverlayClick && 'ignore-react-onclickoutside', modalClassName)}
            closeButtonIconClass="hicon-close text-xxs text-black"
            cardStyle={{ overflow: 'initial' }}
            onClose={this.handleOnClose}
          >
            <h1 className="text-xl mx-lg mb-lg text-center">
              Request Sent!
            </h1>
            <p className="text-xxs m-b-lg text-center">
              {successText}
            </p>
            <p className="text-xxs m-b-lg text-center">
              They will be in touch shortly!
            </p>
          </Modal>,
          document.body,
        )
        : this.buttonRender()
    );
  }
}

export default NewFeatureRequestButton;
