import { isFinite, isNull, isNaN, last, head, isEmpty, isArray, isNumber } from 'lodash';
import NumberHelpers from 'number_helpers';

import {
  today, yesterday, format, subDays, ISODateFormat,
  isSameISOWeek, isSameMonth, isSameQuarter, isSameYear,
  startOfISOWeek, startOfMonth, startOfQuarter, startOfYear,
  endOfISOWeek, endOfMonth, endOfQuarter, endOfYear, isBefore,
} from './date';

import helpers from './helpers';
import { seriesLineWidth } from './charts';

export const truncateString = (str, length) => (str.length > length ? `${str.substr(0, length)}...` : str);

// Number format for Summary sections
export const summarySectionAbbreviationSettings = {
  precision: 2,
  strip_insignificant_zeros: false,
  space_label: false,
  labels: {
    thousand: 'K', million: 'M', billion: 'B', trillion: 'T', quadrillion: 'Q',
  },
  significant: false,
};
export const numberToHumanSummary = (number, config = {}) =>
  NumberHelpers.number_to_human(number || 0, {
    ...summarySectionAbbreviationSettings,
    ...config,
  });

// Number format for Tables
export const tableAbbreviationSettings = {
  precision: 4,
  strip_insignificant_zeros: true,
  space_label: false,
  labels: {
    thousand: 'K', million: 'M', billion: 'B', trillion: 'T', quadrillion: 'Q',
  },
  significant: true,
};
export const numberToHumanTable = (number, config = {}) =>
  NumberHelpers.number_to_human(number || 0, {
    ...tableAbbreviationSettings,
    ...config,
  });

// Number format for Charts
export const chartsAbbreviationSettings = {
  ...summarySectionAbbreviationSettings,
  strip_insignificant_zeros: true,
};
export const numberToHumanChart = (number, config = {}) =>
  NumberHelpers.number_to_human(number || 0, {
    ...chartsAbbreviationSettings,
    ...config,
  });

export const numberToCurrency = (number, config = {}) =>
  NumberHelpers.number_to_currency(number || 0, {
    precision: 2,
    ...config,
  });

export const numberToPercentage = (number, config = {}) =>
  NumberHelpers.number_to_percentage(number || 0, {
    precision: 2,
    ...config,
  });

export const numberToSize = (number, config = {}) =>
  NumberHelpers.number_to_human_size(number || 0, {
    precision: 2,
    strip_insignificant_zeros: true,
    ...config,
  });

export const decorateWithNaN = value => (Number.parseInt(value, 0) === -100500 ? 'NaN' : numberToPercentage(value));

export const simpleNumberToWord = number => ({
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four ',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
  11: 'eleven',
  12: 'twelve',
  13: 'thirteen',
  14: 'fourteen',
  15: 'fifteen',
  16: 'sixteen',
  17: 'seventeen',
  18: 'eighteen',
  19: 'nineteen',
})[number];

export const isPartialPeriod = ({
  period,
  period_to_date,
  period_from_date,
  granularity = 'weekly',
}, {
  isFirst,
  isLast,
  value,
  dataMax,
  dataMin,
}) => {
  if (granularity !== 'daily' && (isFirst || isLast)) {
    let hasPartialPeriod = false;

    const isSame = {
      weekly: isSameISOWeek,
      monthly: isSameMonth,
      quarterly: isSameQuarter,
      yearly: isSameYear,
    };

    const startOf = {
      weekly: startOfISOWeek,
      monthly: startOfMonth,
      quarterly: startOfQuarter,
      yearly: startOfYear,
    };

    const endOf = {
      weekly: endOfISOWeek,
      monthly: endOfMonth,
      quarterly: endOfQuarter,
      yearly: endOfYear,
    };

    if (isFirst) {
      const periodFromDate = period_from_date
        ? format(period_from_date, ISODateFormat) : subDays(today(), parseInt(period, 10));

      if (periodFromDate) {
        const startPeriod = startOf[granularity](periodFromDate);

        if (periodFromDate === startPeriod || !isSame[granularity](dataMin, value)) {
          hasPartialPeriod = false;
        } else {
          hasPartialPeriod = true;
        }
      }
    }

    if (isLast) {
      const periodToDate = period_to_date ? format(period_to_date, ISODateFormat) : yesterday();
      if (periodToDate) {
        const endPeriod = endOf[granularity](periodToDate);
        if (periodToDate === endPeriod || !isSame[granularity](dataMax, value)) {
          hasPartialPeriod = false;
        } else {
          hasPartialPeriod = true;
        }
      }
    }

    return hasPartialPeriod;
  }

  return false;
};

const isStartPeriodPartial = ({ period_from_date, period, granularity }) => {
  if (!granularity || granularity === 'daily') return false;
  const date = period_from_date
    ? format(period_from_date, ISODateFormat) : subDays(today(), parseInt(period, 10));

  const startOf = {
    weekly: startOfISOWeek,
    monthly: startOfMonth,
    quarterly: startOfQuarter,
    yearly: startOfYear,
  };

  const startPeriod = startOf[granularity](date);
  return date !== startPeriod;
};

const isEndPeriodPartial = ({ period_to_date, granularity }) => {
  if (!granularity || granularity === 'daily') return false;

  const date = period_to_date ? format(period_to_date, ISODateFormat) : yesterday();

  const endOf = {
    weekly: endOfISOWeek,
    monthly: endOfMonth,
    quarterly: endOfQuarter,
    yearly: endOfYear,
  };

  const endPeriod = endOf[granularity](date);

  return date !== endPeriod;
};

export const decorateFilledSeriesExport = (
  fData,
  filters,
  value,
  dynamicPartialByLastPoint,
  dataDrivenPartial,
) => {
  let startPartial;
  let endPartial;
  if (!dataDrivenPartial && !isEmpty(filters)) {
    startPartial = isStartPeriodPartial(filters);
    endPartial = isEndPeriodPartial(filters);
    if ((startPartial && !endPartial && !dynamicPartialByLastPoint)) return fData;
  }
  const firstPoint = head(fData);
  const lastPoint = dynamicPartialByLastPoint
    ? fData.findLast(item => isNumber(item[1]) && item[1] !== 'undefined')
    : last(fData);

  if ((!isArray(firstPoint) || !isArray(lastPoint)) && !dataDrivenPartial) return fData;

  const getPointColor = value.decorateColor || (() => value.colorHex || value.color);

  return fData.map((s) => {
    const pointData = { ...s, x: s[0], y: s[1] };
    const pointColor = getPointColor({ x: s[0], y: s[1], ...value });
    const fillPattern = `url(#custom-pattern-${pointColor}-${value.type})`;

    // this part should do early return of partial driven by data from back-end
    // dataDrivenPartial data format { y: Number, isPartialPeriod: bool }

    if (dataDrivenPartial) {
      const dataDrivenPointColor = getPointColor({ x: s.x, y: s.y, ...value });
      const dataDrivenFillPattern = `url(#custom-pattern-${dataDrivenPointColor}-${value.type})`;
      return ({
        y: s.y,
        color: s.isPartialPeriod ? dataDrivenFillPattern : pointColor,
        isPartial: s.isPartialPeriod,
      });
    }

    // this part should do checks of partial by DATES
    if (!dataDrivenPartial) {
      if ((startPartial && s[0] === firstPoint[0]) || (endPartial && s[0] === lastPoint[0])) {
        return ({ ...pointData, color: fillPattern, isPartial: true });
      }

      if (!dynamicPartialByLastPoint && s[0] === lastPoint[0] && endPartial) {
        return ({ ...pointData, color: fillPattern });
      }

      if (
        dynamicPartialByLastPoint
        && isSameMonth(s[0], lastPoint[0])
        && isBefore(format(today()), format(filters.period_to_date, ISODateFormat))) {
        return ({ ...pointData, color: fillPattern });
      }
    }
    return pointData;
  });
};

export const zonesForPartialPeriod = (v, metaSeriesData, filters, dataDrivenPartial) => {
  const { color, type, isHighlighted } = metaSeriesData;
  const disableGradient = isHighlighted && type === 'areaspline';

  // this part should do early return of partial driven by data from back-end
  // dataDrivenPartial data format { y: Number, isPartialPeriod: bool }

  if (dataDrivenPartial) {
    const listObj = {
      fillColor: `url(#custom-pattern-${color}-${type})`,
      dashStyle: ['spline', 'line'].includes(type) ? 'ShortDashDotDot' : 'Solid',
    };

    const result = v.map((point, index) => {
      if (point.isPartialPeriod || (index === 1 && v[0].isPartialPeriod)) {
        return { ...point, value: index, ...listObj };
      }
      return {
        value: index,
        fillColor: color && {
          linearGradient: {
            x1: 0, y1: 0, x2: 0, y2: 1,
          },
          stops: [
            [0, helpers.hexToRGB(color, disableGradient ? 1 : 0.8)],
            [1, helpers.hexToRGB(color, disableGradient ? 1 : 0.01)],
          ],
        },
        lineWidth: type !== 'spline' ? 0 : seriesLineWidth,
      };
    });

    return result;
  }

  // this part should do checks of partial by DATES

  if (!isEmpty(v) && v[1]) {
    const listObj = {
      fillColor: `url(#custom-pattern-${color}-${type})`,
      dashStyle: type === 'spline' ? 'ShortDashDotDot' : 'Solid',
    };
    const pastZone = isStartPeriodPartial(filters)
      ? { value: v[1][0], ...listObj }
      : { value: v[1][0] };

    const futureZone =
      isEndPeriodPartial(filters)
        ? { value: last(v)[0], ...listObj }
        : { value: last(v)[0] };

    let result = [
      {
        value: isArray(v[v.length - 2]) ? v[v.length - 2] && v[v.length - 2][0] : v[v.length - 2].x,
        fillColor: color && {
          linearGradient: {
            x1: 0, y1: 0, x2: 0, y2: 1,
          },
          stops: [
            [0, helpers.hexToRGB(color, disableGradient ? 1 : 0.8)],
            [1, helpers.hexToRGB(color, disableGradient ? 1 : 0.01)],
          ],
        },
        lineWidth: type !== 'spline' ? 0 : seriesLineWidth,
      },
      {
        ...futureZone,
      },
    ];

    result = (v[1][0] && v.length > 2) ? [pastZone, ...result] : result;

    return result;
  }
  return [];
};

export const percentageOf = (value, base, precision = 1) => {
  // eslint-disable-next-line no-restricted-properties
  const factor = Math.pow(10, precision);
  const newBase = base === 0 ? 1 : base;
  return Math.round(((value * 100.0) / newBase) * factor) / factor;
};

export const formattedYesterday = format(yesterday());

export default {
  numberWithCommas: (x, with_zero = false) => {
    if (!x) return '';
    const parts = x.toString().split('.');
    if (with_zero) {
      let nextPart = parts[0].replace(/,/gi, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      parts[0] = nextPart;
      // next needed to replace 0 if its in front of integer - ex 012 should be 12
      if (nextPart.length > 1 && nextPart.slice(0, 1) === '0' && nextPart.slice(0, 2) !== '0.') {
        parts[0] = nextPart.substring(1);
      }
    } else {
      parts[0] = parts[0].replace(/,/gi, '').replace(/^0+/, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return parts.join('.');
  },

  capitalize: string => string.charAt(0).toUpperCase() + string.slice(1),

  formatPercentage: (value, presicion = 1) => {
    let roundedValue = Math.round(value * 10000) / 100;
    if (isNaN(roundedValue) || !isFinite(roundedValue)) {
      roundedValue = 0;
    }
    if (roundedValue === 0.0) {
      roundedValue = 0;
    }
    return `${roundedValue.toFixed(presicion)}%`;
  },

  formatPercentageOf: (value, base, precision) => `${percentageOf(value, base, precision)}%`,

  formatMinMaxString: (min = '', max = '') => {
    let str = '';
    const minVal = min.toString().replace(/[^0-9]/gi, '');
    const maxVal = max.toString().replace(/[^0-9]/gi, '');
    let resMinVal = 0;
    let resMaxVal = 0;
    if (minVal) {
      resMinVal = minVal > 999 ? NumberHelpers.number_to_human(minVal, {
        space_label: false, strip_insignificant_zeros: true, labels: { thousand: 'k', million: 'm', billion: 'b' },
      }) : minVal;
    }
    if (maxVal) {
      resMaxVal = maxVal > 999 ? NumberHelpers.number_to_human(maxVal, {
        space_label: false, strip_insignificant_zeros: true, labels: { thousand: 'k', million: 'm', billion: 'b' },
      }) : maxVal;
    }

    if (resMinVal && resMaxVal) {
      str = `${resMinVal} - ${resMaxVal}`;
    } else {
      if (minVal) {
        str = `> ${resMinVal}`;
      }
      if (maxVal) {
        str = `< ${resMaxVal}`;
      }
    }
    return str.toString().toLowerCase();
  },

  formatMinMaxARPUString: (min, max) => {
    let str = '';
    const resMinVal = (isNaN(min) || isNull(min) || min === '') ? '' : Number(min).toFixed(2);
    const resMaxVal = (isNaN(max) || isNull(max) || max === '') ? '' : Number(max).toFixed(2);
    if (resMinVal && resMaxVal) {
      str = `$${resMinVal} - $${resMaxVal}`;
    } else {
      if (resMinVal) {
        str = `> $${resMinVal}`;
      }
      if (resMaxVal) {
        str = `< $${resMaxVal}`;
      }
    }
    return str;
  },
  formatDateToQuorter: (date) => {
    let s = '';
    if (Highcharts.dateFormat('%b', date) === 'Jan') {
      s += 'Q1 ';
    }
    if (Highcharts.dateFormat('%b', date) === 'Apr') {
      s += 'Q2 ';
    }
    if (Highcharts.dateFormat('%b', date) === 'Jul') {
      s += 'Q3 ';
    }
    if (Highcharts.dateFormat('%b', date) === 'Oct') {
      s += 'Q4 ';
    }
    s += Highcharts.dateFormat('%Y', date);
    return s;
  },

  customPeriodText: (period, dateFrom, dateTo) => {
    let activeText = '';
    if (dateTo && dateFrom && !period) {
      activeText = `${format(dateFrom)} - ${format(dateTo)}`;
    } else {
      const d1 = format(yesterday());
      const d2 = format(subDays(yesterday(), period));
      activeText = `${d2} - ${d1}`;
    }
    return activeText;
  },

  formatDateMDY: (date) => {
    if (!date) return;
    const d = new Date(date);
    return [d.getMonth() + 1, d.getDate(), d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('/');
  },
  priceAbbreviationSettings: {
    precision: 0,
    strip_insignificant_zeros: true,
  },
  downloadsAbbreviationSettings: {
    precision: 4,
    strip_insignificant_zeros: true,
    space_label: false,
    labels: {
      thousand: 'K', million: 'M', billion: 'B', trillion: 'T', quadrillion: 'Q',
    },
  },
  numberWithDecimals: (v, decimals = 1, abbreviationSettings) => {
    const humanNum = NumberHelpers.number_to_human(v || 0, abbreviationSettings);
    const withDecimal = parseFloat(humanNum.substring(0, humanNum.length - 1)).toFixed(decimals);
    const abbr = humanNum[humanNum.length - 1];
    return v > 999 ? `${withDecimal}${abbr}` : v;
  },
};

// Number format for run time
export const runTimeAbbreviationSettings = {
  precision: 4,
  strip_insignificant_zeros: true,
  space_label: false,
  labels: {
    thousand: 'k',
  },
  significant: true,
};
export const numberToHumanRunTime = number =>
  NumberHelpers.number_to_human(number || 0, {
    ...runTimeAbbreviationSettings,
  });
