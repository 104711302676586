import React from 'react';
import PropTypes from 'prop-types';


const DefaultItem = ({ item }) =>
  (
    <div key={item.value} className="tt-suggestion">
      <div className="category-item">
        <div className="d-flex aling-items-center text-truncate" style={{ minHeight: '3rem' }}>
          <h4 className="m-b-0">{item.text}</h4>
        </div>
      </div>
    </div>
  );

DefaultItem.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default DefaultItem;

