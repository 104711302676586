import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { isEmpty } from 'lodash';
import cx from 'classnames';

import Button from './Buttons/Button';

class ModalContent extends Component {
  static propTypes = {
    cardSize: PropTypes.oneOf(['xs', 'sm', 'lg', 'xl', 'xxl']),
    handleClose: PropTypes.func,
    clickOutside: PropTypes.func,
    cardStyle: PropTypes.object,
    children: PropTypes.any,
    closeButtonIconClass: PropTypes.string,
    modalContentClass: PropTypes.string,
    hideCloseButton: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool,
    closeButtonVersionButton: PropTypes.bool,
  };
  static defaultProps = {
    cardSize: 'sm',
    modalContentClass: '',
    cardStyle: {},
    hideCloseButton: false,
    shouldCloseOnOverlayClick: false,
    closeButtonIconClass: '',
  };

  handleClickOutside = (e) => {
    if (e.target.offsetParent && !this.props.shouldCloseOnOverlayClick) return false;
    this.props.clickOutside();
  }
  render() {
    const {
      cardSize, cardStyle, handleClose,
      children, closeButtonIconClass, modalContentClass,
      hideCloseButton, closeButtonVersionButton,
    } = this.props;
    return (
      <div className={`modal-content card-simple card-${cardSize} ${modalContentClass}`} style={cardStyle}>
        {!hideCloseButton && (closeButtonVersionButton ? (
          <Button
            variant="secondary"
            shape="square"
            className="modal-close-button"
            onClick={handleClose}
          >
            <i className={cx(closeButtonIconClass || 'hicon-close-2')} />
          </Button>
        ) : (
          <div
            className={cx('d-flex align-items-center justify-content-center modal-close')}
            onClick={handleClose}
          >
            {isEmpty(closeButtonIconClass) ? 'x' : <i className={cx('text-quiet', closeButtonIconClass)} />}
          </div>
        ))}
        {children}
      </div>
    );
  }
}

export default onClickOutside(ModalContent);
