import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';


const NotFoundState = ({ text, disabled }) =>
  (
    <li className={cx('not-found-state', { disabled })}>
      <div className="d-flex text-center align-items-center bg-white" style={{ minHeight: 100 }}>
        <span className="text-xxs text-quiet mx-auto my-auto">{text}</span>
      </div>
    </li>
  );

NotFoundState.propTypes = {
  text: PropTypes.any,
  disabled: PropTypes.bool,
};

export default NotFoundState;
