import React from 'react';
import PropTypes from 'prop-types';

import { storeName } from './shared';

const SdkItem = ({ sdk }) =>
  (
    <div key={sdk.value} className="tt-suggestion">
      <div className="category-item">
        <div className="d-flex align-items-center">
          <h4 className="m-b-0">
            {sdk.store && <i className={`store-icon ${storeName(sdk.store)} mr-xxs`} />}
            {sdk.name}
            {sdk.kind === 'sdk' && ' (SDK)'}
          </h4>
        </div>
      </div>
    </div>
  );

SdkItem.propTypes = {
  sdk: PropTypes.shape({
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    store: PropTypes.string,
    kind: PropTypes.string,
    added: PropTypes.bool,
  }).isRequired,
};

export default SdkItem;

