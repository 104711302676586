import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from './Tooltip';

const SwitchButtons = ({
  options,
  active,
  btnClassName,
  size,
  btnStyle,
  containerClassName,
  containerStyles,
  onChange,
  disabled,
  showAsMenu,
}) => {
  const elements = options.map((option) => {
    const isActive = active === option.value;
    return (
      <Fragment key={option.value}>
        {(disabled || option.disabled) && (
          <Tooltip content={option.tooltip && option.tooltip}>
            <div
              className={cx('disabled-div', btnClassName, size ? `btn-${size}` : 'btn-xs', { active: isActive })}
              style={btnStyle}
              type="div"
            >
              {option.iconClass && <i className={option.iconClass} />}
              {option.text}
            </div>
          </Tooltip>
        )}
        {!(disabled || option.disabled) && (
          <Tooltip content={option.tooltip && option.tooltip}>
            <button
              className={cx(btnClassName, size ? `btn-${size}` : 'btn-xs', { active: isActive })}
              style={btnStyle}
              type="button"
              onClick={() => (isActive ? null : onChange(option.value))}
            >
              {option.iconClass && <i className={option.iconClass} />}
              {option.text}
            </button>
          </Tooltip>
        )}
      </Fragment>
    );
  });
  return (
    <div className={cx('btn-group', containerClassName, showAsMenu && 'btn-group-menu')} style={containerStyles} data-testid="switch-buttons">
      {elements}
    </div>
  );
};

SwitchButtons.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.any,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    iconClass: PropTypes.string,
    tooltip: PropTypes.string,
  })).isRequired,
  active: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  btnClassName: PropTypes.string,
  btnStyle: PropTypes.object,
  containerClassName: PropTypes.string, // supports: ['', 'btn-group-text-buttons']
  containerStyles: PropTypes.object,
  disabled: PropTypes.bool,
  showAsMenu: PropTypes.bool,
  size: PropTypes.string,
};

SwitchButtons.defaultProps = {
  btnClassName: 'btn btn-outline',
  btnStyle: {},
  containerClassName: '',
  containerStyles: {},
  disabled: false,
};

export default SwitchButtons;
