import React from 'react';

const ErrorMessage = () =>
  (
    <li className="disabled">
      <div className="d-flex text-center bg-white pb-xs" style={{ height: 100 }}>
        <span className="text-xxs text-quiet m-x-auto m-y-auto">An error occurred, please redo search.</span>
      </div>
    </li>
  );

export default ErrorMessage;

