import React from 'react';
import NumberHelpers from 'number_helpers';
import helpers from '../../utils/helpers';

export const priceAbbreviationSettings = {
  precision: 0,
  strip_insignificant_zeros: true,
};

export const downloadsAbbreviationSettings = {
  precision: 4,
  strip_insignificant_zeros: true,
  space_label: false,
  labels: { thousand: 'K', million: 'M', billion: 'B' },
};

export const downloadsRender = dls => (
  <div className="d-flex align-items-center mx-auto px-xs" style={{ width: 90 }}>
    <i className="hicon-download-thin text-16 mr-xs" />
    {NumberHelpers.number_to_human((dls || 0), downloadsAbbreviationSettings)}
  </div>
);

export const storeName = store => helpers.storeIconClasses[store];
