/* eslint-disable object-shorthand */
import { map, reduce, sortBy, isEmpty, includes, remove, forEach, keys, values, mapValues, get, uniq, last } from 'lodash';
import { yesterday, format, subWeeks, subMonths, subQuarters, subYears, startOfISOWeek } from './date';
import usStates from './usa_states';

export const getScrollableContainer = () => window.document.querySelector('.layout-publisher #content') || window;
export const getDropdownRootContainer = () => window.document.getElementById('portal-root-dropdown');

export const demoPagePath = () => '/demo';

// Stores
export const isUnified = store => ['unified', 'both'].includes(store);
export const isSingleStore = store => ['itunes_connect', 'google_play'].includes(store);
export const appStore = store => (isUnified(store) ? 'unified' : store);

// RefNos
export const isSameRefNo = (refNo1, refNo2) => {
  if (!refNo1 || !refNo2) return false;
  return refNo1.toString() === refNo2.toString();
};

export const getAppRefs = () => {
  const targetNode = document.getElementById('app-insights-general-root');
  const targetNode2 = document.getElementById('breadcrumbs-general-root');

  if (targetNode || targetNode2) {
    return JSON.parse((targetNode || targetNode2).dataset.appRefs);
  }

  return {};
};

export const refNoByStore = store => getAppRefs()[store === 'unified' ? 'both' : store];

const mapCountries = ({ includeWW, exclude, labelKey = 'label' } = {}) => {
  const initialValue = includeWW ? [{ value: 'WW', [labelKey]: 'Worldwide' }] : [];
  return reduce(window.SORTED_COUNTRIES, (result, countryName, countryIso) => {
    if (exclude && exclude.includes(countryIso)) return result;

    return [
      ...result,
      { value: countryIso, [labelKey]: countryName },
    ];
  }, initialValue);
};

export const makeCountriesOptions = (countriesIso, { exclude, enableUSStates } = {}) => {
  const countriesOptions = countriesIso.reduce((memo, country) => {
    const countryOption = {
      value: country,
      label: country === 'WW' ? 'Worldwide' : COUNTRIES_ARRAY[country],
      ...((enableUSStates && country === 'US') ? { children: usStates } : {}),
    };

    if (['WW', 'US'].includes(country)) {
      return { ...memo, [country]: countryOption };
    }

    return { ...memo, restCountries: [...memo.restCountries, countryOption] };
  }, { restCountries: [] });

  const result = [
    ...(countriesOptions.WW ? [countriesOptions.WW] : []),
    ...(countriesOptions.US ? [countriesOptions.US] : []),
    ...sortBy(countriesOptions.restCountries, ['label']),
  ];

  return exclude ? result.filter(({ value }) => !exclude.includes(value)) : result;
};

export const mapASOCountries = () => window.ASO_COUNTRIES.map(iso => ({
  value: iso,
  label: window.COUNTRIES_ARRAY[iso],
}));

export const makeAppPermissionsByStore = ({ permissionFormat } = {}) => (
  reduce(APP_PERMISSIONS, (results, permission, permissionInternalKey) => {
    const storeKey = IC_APP_PERMISSIONS.includes(permissionInternalKey) ? 'itunes_connect' : 'google_play';
    const permissionOption = {
      ...permission,
      label: permission.name,
      value: permissionInternalKey,
      store: storeKey,
    };

    return {
      ...results,
      [storeKey]: [
        ...results[storeKey],
        permissionFormat ? permissionFormat(permissionOption) : permissionOption,
      ],
    };
  }, { itunes_connect: [], google_play: [] })
);

export const makeAppPermissionsByCategory = ({
  includeUnified = false,
  excludeCategories,
  permissionFormat,
} = {}) => {
  const groupedByStore = makeAppPermissionsByStore();
  const groupedByStoreAndCategory = mapValues(groupedByStore, permissions => (
    permissions.reduce((acc, permission) => {
      const permissionCategory = permission.category;

      if (excludeCategories && excludeCategories.includes(permissionCategory)) return acc;

      return {
        ...acc,
        [permissionCategory]: [
          ...get(acc, [permissionCategory], []),
          permissionFormat ? permissionFormat(permission) : permission,
        ],
      };
    }, {})
  ));

  if (includeUnified) {
    const { itunes_connect: permsIOS, google_play: permsGP } = groupedByStoreAndCategory;
    const permissionCategories = uniq([...keys(permsIOS), ...keys(permsGP)]);
    const combinedPermissions = permissionCategories.reduce((acc, permissionCategory) => ({
      ...acc,
      [permissionCategory]: [
        ...get(permsIOS, permissionCategory, []),
        ...get(permsGP, permissionCategory, []),
      ],
    }), {});

    return {
      ...groupedByStoreAndCategory,
      unified: combinedPermissions,
    };
  }

  return groupedByStoreAndCategory;
};

const categoryNameForId = (id, store) => {
  if (store && !isUnified(store)) return CATEGORIES[store][id];
  return {
    ...CATEGORIES.itunes_connect,
    ...CATEGORIES.google_play,
  }[id];
};
const trim = str => str.replace(/^\s+|\s+$/gm, '');

export const parseCategories = (triggerOptions) => {
  let presentCategories;
  if (triggerOptions.downloads && triggerOptions.downloads.category_list.length > 0) {
    presentCategories = triggerOptions.downloads.category_list;
  } else if (triggerOptions.free && triggerOptions.free.category_list.length > 0) {
    presentCategories = triggerOptions.free.category_list;
  } else if (triggerOptions.paid && triggerOptions.paid.category_list.length > 0) {
    presentCategories = triggerOptions.paid.category_list;
  } else {
    presentCategories = triggerOptions.grossing.category_list;
  }
  return presentCategories;
};

export const canonicalSdkName = name =>
  name.replace(/[^\d\w]/g, '').replace('_', '').toLowerCase().replace('logo', '');

export const sdkImageUrl = (store, name) =>
  `https://d1cxddrn475swp.cloudfront.net/frameworks/${store}/${name}.jpg`;

export const assetMapping = {
  apps: ['app', 'unified_app'],
  publishers: ['publisher', 'unified_publisher'],
  companies: ['company'],
  va_companies: ['va_company'],
  sdks: ['sdk'],
  keywords: ['keyword'],
  users: ['user'],
  reports: ['report'],
  markets: ['market'],
};

export const restrictedFinanceAssets = ['apps', 'companies', 'publishers'];

export const getAssetLocation = (asset) => {
  switch (asset.kind) {
    case 'unified_app':
      return `/apps/unified/${asset.ref_no}`;
    case 'app':
      return `/apps/${asset.store}/${asset.ref_no}`;
    case 'unified_publisher':
      if (asset.store === 'both') { return `/publishers/itunes_connect/${asset.ref_no}/unified`; }
      return `/publishers/unified/${asset.ref_no}`;
    case 'company':
      return `/companies/${asset.ref_no}`;
    case 'keyword':
      return `/keyword_insights/organic/${asset.ref_no}`;
    default:
      return `/${asset.kind}s/${asset.store}/${asset.ref_no}`;
  }
};

export const unifiedRefNoBy = ({ ref_no, store, kind }) => {
  switch (kind) {
    case 'app':
      if (store === 'unified') {
        return `UA:${ref_no}`;
      }
      return `A:${store}:${ref_no}`;
    case 'unified_app':
      return `UA:${ref_no}`;
    case 'publisher':
      if (store === 'unified') {
        return `UP:${ref_no}`;
      }
      return `P:${store}:${ref_no}`;
    case 'unified_publisher':
      return `UP:${ref_no}`;
    case 'company':
      return `UC:${ref_no}`;
    default:
      break;
  }
};

export const unicodeToPlainText = text =>
  text.replace(/\\+u([0-9a-fA-F]{4})/g, (a,b) => String.fromCharCode(parseInt(b, 16)));

export const parseUnifiedRefNo = (unifiedRefNo) => {
  if (!unifiedRefNo) return unifiedRefNo;

  const [prefix, ...restParams] = unifiedRefNo.split(':');

  const kind = {
    A: 'app',
    UA: 'unified_app',
    P: 'publisher',
    UP: 'unified_publisher',
    UC: 'company',
  }[prefix];

  const store = restParams.length > 1 ? restParams[0] : 'unified';

  return {
    kind,
    ref_no: last(restParams),
    ...(kind !== 'company' ? { store } : {}),
  };
};

export const parsePeriodFilters = (filtersString) => {
  const parsed = filtersString ? JSON.parse(filtersString) : {};
  if (parsed.period_to_date) { return parsed; }

  return {
    ...parsed,
    period_to_date: format(yesterday()),
  };
};

export const getFallbackSubDateByGranularity = ({ isOverPeriod, granularity, date }) => isOverPeriod ? {
  weekly: subWeeks(date, 1),
  monthly: subMonths(date, 1),
  quarterly: subQuarters(date, 1),
}[granularity] : {
  weekly: startOfISOWeek(subYears(date, 1)),
  monthly: subYears(date, 1),
  quarterly: subYears(date, 1),
}[granularity];

export const validateEmailName = (name) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))$/;
  return re.test(name);
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const normalizeChartType = (chartType) => {
  const chartTypeReplacements = {
    line: 'spline',
    area: 'areaspline',
  };

  return chartTypeReplacements[chartType] || chartType;
};

export const normalizeSavedViewsChartTypes = savedViews => savedViews.map(savedView => ({
  ...savedView,
  chart_configs: {
    ...savedView.chart_configs,
    primary_chart_type: normalizeChartType(savedView.chart_configs.primary_chart_type),
    ...(savedView.chart_configs.secondary_chart_type
      ? { secondary_chart_type: normalizeChartType(savedView.chart_configs.secondary_chart_type) }
      : {}
    ),
  },
}));

export default {
  categoryNameForId,
  appCategoriesNames: (primary_category_id, categories_ids = [], store) => {
    let categories = '';
    if (primary_category_id) {
      categories = categoryNameForId(primary_category_id, store) || '';
      const sub_category_id = Number(categories_ids.filter(id => id !== primary_category_id)[0]);
      if (sub_category_id) categories += ` > ${categoryNameForId(sub_category_id, store)}`;
    }
    return categories;
  },
  mapCountries,
  mapCategoriesByStoreForMultilevelDropdown: (
    categories, hasAllCategories = true, disabledCategories = [], store,
  ) => {
    const storeOption = store ? { store } : {};
    const rootMenuItems = hasAllCategories ? [{ value: 'all', text: 'All Categories' }] : [];
    const subMenues = [];
    categories.forEach(([rootItemKey, rootItemValue]) => {
      if (!includes(disabledCategories, rootItemKey)) {
        if (Object.prototype.toString.call(rootItemValue) === '[object Object]') {
          const subCategoryEntries = Object.entries(rootItemValue)[0];
          const nodeText = store ? `${subCategoryEntries[0]}__${store}` : subCategoryEntries[0];
          const subMenuItems = Object.entries(subCategoryEntries[1]).map(([value, text]) => ({
            value,
            text,
            ...storeOption,
          }));
          subMenues[nodeText] = sortBy(subMenuItems, 'text');
          rootMenuItems.push({
            isNode: true,
            value: rootItemKey,
            text: nodeText,
            ...storeOption,
          });
        } else {
          rootMenuItems.push({ value: rootItemKey, text: rootItemValue, ...storeOption });
        }
      }
    });
    return { rootMenuItems, subMenues };
  },

  hexToRGB: (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
  },
  rgbaToHex: (rgba) => {
    const parts = rgba.substring(rgba.indexOf('(')).split(',');
    const r = parseInt(trim(parts[0].substring(1)), 10);
    const g = parseInt(trim(parts[1]), 10);
    const b = parseInt(trim(parts[2]), 10);
    const a = parseFloat(trim(parts[3].substring(0, parts[3].length - 1))).toFixed(2);

    return (`#${r.toString(16)}${g.toString(16)}${b.toString(16)}${(a * 255).toString(16).substring(0, 2)}`);
  },
  dropdownOptionsFromObject: obj => map(obj, (val, key) => ({ value: key, text: val })),
  mapAdCountries: ({ includeWW } = {}) => {
    const sortedCountries = sortBy(
      map(TOP_AD_COUNTRIES, iso => ({ value: iso, label: COUNTRIES_ARRAY[iso] })),
      country => country.label,
    );
    const usCountry = remove(sortedCountries, country => country.value === 'US')[0];
    sortedCountries.unshift(usCountry);
    return includeWW ? [{ value: 'WW', label: 'Worldwide' }, ...sortedCountries] : sortedCountries;
  },
  storeIconClasses: {
    itunes_connect: 'hicon-apple',
    google_play: 'hicon-play',
    both: 'hicon-unified',
    unified: 'hicon-unified',
  },
  storeNames: {
    itunes_connect: 'iTunes Connect',
    google_play: 'Google Play',
    unified: 'Unified',
    both: 'Both',
  },
  shortStoreNames: {
    itunes_connect: 'iOS',
    google_play: 'GP',
    unified: 'Unified',
    both: 'Both',
  },
  storeColors: {
    itunes_connect: '#895AC3',
    google_play: '#49B480',
    unified: '#895AC3',
    both: '#895AC3',
  },

  validateEmail,

  validateEmailName,

  decodeEntities: str =>
    (str || '').replace(/\\u(\w\w\w\w)/g, (a, b) => {
      const charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    }),
  storeStaticIconsUrls: store => ({
    itunes_connect: 'https://engage.apptopia.com/hubfs/store-icons/icon-i-os-gray@2x.png',
    google_play: 'https://engage.apptopia.com/hubfs/store-icons/icon-gp-gray@2x.png',
    unified: 'https://engage.apptopia.com/hubfs/store-icons/icon-unified@2x.png',
  })[appStore(store)],

  getCategoryName: (categoryId, store) => {
    if (!categoryId) { return ''; }

    let parentCategoryName;

    forEach(MULTILEVEL_CATEGORIES[store], (v) => {
      if (typeof v[1] === 'object') {
        if (includes(keys(values(v[1])[0]), categoryId.toString())) {
          parentCategoryName = [keys(v[1])];
        }
      }
    });

    if (parentCategoryName) {
      return `${parentCategoryName} > ${categoryNameForId(categoryId, store)}`;
    }

    return categoryNameForId(categoryId, store) || '';
  },
  convertToSlug: (text) => {
    if (!text) return;
    return text.toLowerCase().replace(/[^\w ]+/g, '').replace('_', '-').replace(/ +/g, '-');
  },

  updateInputValue: ({ target: { name, value } }, setters = {}) => {
    const {
      setError,
      setName,
      setMailVal,
      setCompanyName,
      setPasswords,
      setErrorPass,
      passwords,
      initialPasswords = false,
      setEmailWithDomain,
    } = setters;

    const isFormNewPassChanged = passwords && passwords.password && passwords.password_confirmation;
    if (['first_name', 'last_name'].includes(name)) {
      const nameValid = validateEmailName(value);
      const nameString = name.replace('_', ' ');
      if (nameValid) {
        setError(prevError => ({ ...prevError, [name]: '' }));
        setName(name, value);
      } else if (!value) {
        setError(prevError => ({ ...prevError, [name]: `Enter a valid ${nameString}` }));
        setName(name, value);
      } else {
        setError(prevError => ({ ...prevError, [name]: `Please enter ${nameString}` }));
      }
    }
    if (name === 'email') {
      const mailValid = validateEmailName(value);
      if (mailValid) {
        setError(prevError => ({ ...prevError, [name]: '' }));
        setMailVal(value);
      } else if (!value) {
        setError(prevError => ({ ...prevError, [name]: 'Enter a valid email' }));
        setMailVal(value);
      } else {
        setError(prevError => ({ ...prevError, [name]: 'Please enter new email without domain' }));
      }
    }
    if (name === 'emailWithDomain') {
      const mailValid = validateEmail(value);
      if (mailValid) {
        setError(prevError => ({ ...prevError, [name]: '' }));
      } else if (!value || !mailValid) {
        setError(prevError => ({ ...prevError, [name]: 'Enter a valid email' }));
      }
      setEmailWithDomain(value);
    }
    if (name === 'company_name') {
      setError(prevError => ({ ...prevError, [name]: '' }));
      setCompanyName(value);
    }
    if (['current_password', 'password'].includes(name)) {
      setPasswords(prevPasswords => ({ ...prevPasswords, [name]: value }));
      if ((value.length > 0 && value.length < 8) || value.length > 128) {
        setErrorPass(prevErrorPass => ({ ...prevErrorPass, [name]: 'Passwords must have at least 8 characters.' }));
      } else if (value !== passwords.password_confirmation && passwords.password_confirmation.length >= 8) {
        setErrorPass(prevErrorPass => ({ ...prevErrorPass, [name]: 'Passwords do not match' }));
      } else {
        setErrorPass(prevErrorPass => ({ ...prevErrorPass, [name]: '' }));
      }
    }
    if (name === 'password_confirmation') {
      setPasswords(prevPasswords => ({ ...prevPasswords, [name]: value }));
      if (value !== passwords.password) {
        setErrorPass(prevErrorPass => ({ ...prevErrorPass, [name]: 'Passwords do not match' }));
      } else {
        setErrorPass(prevErrorPass => ({ ...prevErrorPass, [name]: '' }));
      }
    }
    if (!initialPasswords && ['password_confirmation', 'password'].includes(name)) {
      if (isEmpty(passwords.current_password) && isFormNewPassChanged) {
        setErrorPass(prevErrorPass => ({ ...prevErrorPass, current_password: 'Please enter your current password' }));
      }
    }
  },
};
