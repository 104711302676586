import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { isNull } from 'lodash';

import helpers from '../utils/helpers';

class AppIcon extends Component {
  static propTypes = {
    alt: PropTypes.string,
    url: PropTypes.string,
    url2x: PropTypes.string,
    store: PropTypes.oneOf(['itunes_connect', 'google_play', 'both', 'unified']),
    size: PropTypes.number,
    containerClasses: PropTypes.string,
    containerStyle: PropTypes.object,
    iconStyle: PropTypes.object,
    hideHtmlTitle: PropTypes.bool,
    withBorder: PropTypes.bool,
    defaultBrokenNoStoreIcon: PropTypes.string,
  };
  static defaultProps = {
    alt: 'app',
    size: 32,
    hideHtmlTitle: false,
    withBorder: false,
    defaultBrokenNoStoreIcon: 'hicon-question-circle',
  };

  static getDerivedStateFromProps(props, state) {
    if (props.url !== state.imageUrl) {
      return { brokenImage: true, imageUrl: props.url };
    }
    return null;
  }

  state = {
    brokenImage: isNull(this.props.url) || this.props.url === undefined,
    imageUrl: this.props.url,
  };

  onImageLoadError = () => this.setState({ brokenImage: true });
  onImageLoad = () => this.setState({ brokenImage: false });
  defaultIcon = () => (
    <div
      className="img-rounded d-flex default-icon"
      style={{
        width: `${this.props.size}px`,
        height: `${this.props.size}px`,
        backgroundColor: 'rgb(206, 210, 219)',
      }}
    >
      <i
        className={cx('m-a-auto',
          helpers.storeIconClasses[this.props.store],
          !this.props.store ? this.props.defaultBrokenNoStoreIcon : '',
        )}
        style={{
          color: 'rgb(237, 240, 245)',
          fontSize: `${this.props.size / 2}px`,
        }}
      />
    </div>
  )
  render() {
    const {
      alt,
      url,
      url2x,
      size,
      containerClasses,
      containerStyle,
      iconStyle,
      hideHtmlTitle,
      withBorder,
    } = this.props;
    return (
      <div
        title={hideHtmlTitle ? null : alt}
        className={cx(containerClasses)}
        style={containerStyle}
      >
        {this.state.brokenImage && this.defaultIcon()}
        {
          <img
            className={cx('img-rounded img-fluid', { hidden: this.state.brokenImage, 'b-a': withBorder })}
            src={url}
            srcSet={url2x && url2x.length > 0 && `${url2x} 2x`}
            onError={this.onImageLoadError}
            onLoad={this.onImageLoad}
            style={{
              minWidth: `${size}px`,
              width: `${size}px`,
              height: `${size}px`,
              ...(iconStyle || {}),
            }}
            alt={`${alt} icon`}
          />
        }
      </div>
    );
  }
}

export default AppIcon;
