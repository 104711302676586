import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isEmpty, includes, uniqBy, get, reduce } from 'lodash';
import { AsyncTypeahead, MenuItem, Menu } from 'react-bootstrap-typeahead';
import LoadingSpinner from '../LoadingSpinner';

import {
  AppItem, PublisherItem, CompanyItem, SdkItem,
  KeywordItem, MarketItem, ReportItem, DefaultItem,
  NotFoundState, ErrorMessage, EntityConflictMessages,
} from '../TypeaheadItems';
import axios from '../../configs/axios';
import { assetMapping } from '../../utils/helpers';

const GROUPED_RESULTS = {
  apps: { loading: true, data: [] },
  companies: { loading: true, data: [] },
  publishers: { loading: true, data: [] },
  sdks: { loading: true, data: [] },
  keywords: { loading: true, data: [] },
  markets: { loading: true, data: [] },
  va_companies: { loading: true, data: [] },
};

const errorsMessages = {
  entitiesPresent: `Some results contain entities already in your market.
                    Adding these will replace existing entities.`,
  entitiesPresentStore: `Some results contain store-specific or unified versions of apps already in your market.
                        Adding these will replace the current version in your market.`,
  conflictsDetected: "Conflicts detected with another user's edits to this list. Please reload the page before making further changes.",
};

const loadingSpinner = (
  <span className="d-inline-flex p-t-xxl">
    <LoadingSpinner size="sm" id="search-typeahead-spinner" />
    &nbsp;Searching...
  </span>
);

const defaultOffsets = (assets) => {
  if (!assets) return {};

  return assets.reduce((memo, asset) => {
    // eslint-disable-next-line no-param-reassign
    memo[asset] = 0;
    return memo;
  }, {});
};

class SearchTypeahead extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onReplaceClick: PropTypes.func,
    onSearchCallback: PropTypes.func,
    onFocus: PropTypes.func,
    onInputChange: PropTypes.func,
    onBeforeSearchCallback: PropTypes.func,
    decorateResultsOnFocus: PropTypes.func,
    onEnterDownCallback: PropTypes.func,
    itemSelected: PropTypes.func,
    decorateItems: PropTypes.func,
    renderMenuItem: PropTypes.func,
    menuHeader: PropTypes.element,
    renderCustomMenuItems: PropTypes.func,
    menuFooter: PropTypes.element,
    customMenuFooter: PropTypes.object,
    assets: PropTypes.array,
    decorators: PropTypes.array,
    defaultSearchResults: PropTypes.array,
    defaultSearchResultsHeader: PropTypes.node,
    requestOptions: PropTypes.object,
    limit: PropTypes.number,
    minLength: PropTypes.number,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    clearButton: PropTypes.bool,
    includesUnified: PropTypes.bool,
    disableSearchOnFocus: PropTypes.bool,
    disableRecentResults: PropTypes.bool,
    searchAsset: PropTypes.oneOf(['apps', 'companies', 'publishers', 'sdks', 'keywords', 'users', 'reports', 'markets', 'va_companies']),
    cancelRequestsAfterSelect: PropTypes.bool,
    searchBy: PropTypes.oneOf(['name', 'keyword']),
    store: PropTypes.oneOf(['itunes_connect', 'google_play', 'both', 'unified', '']),
    bsSize: PropTypes.oneOf(['sm', 'lg']),
    typeaheadId: PropTypes.string,
    maxHeight: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    defaultSearchQuery: PropTypes.string,
    advancedRequestConfig: PropTypes.object, // { [assetName]: { endpoint: '...', params: {} } }
    defaultNoResultsMessage: PropTypes.any,
    menuStyles: PropTypes.object,
    onBlur: PropTypes.func,
    onMenuToggle: PropTypes.func,
    open: PropTypes.bool,
    onPageChange: PropTypes.func,
    total: PropTypes.number,
    disabledOnError: PropTypes.bool,
    AppItemProps: PropTypes.shape({
      component: PropTypes.elementType,
    }),
    PublisherItemProps: PropTypes.shape({
      component: PropTypes.elementType,
    }),
    KeywordItemProps: PropTypes.shape({
      addKeywordText: PropTypes.string,
      activeKeyword: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isAddedKeyword: PropTypes.bool,
      handleAddKeyword: PropTypes.func,
    }),
    labelKey: PropTypes.string,
    errorsMessages: PropTypes.object,
    reSearchFlag: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  };
  static defaultProps = {
    className: 'd-block',
    placeholder: 'Search any app',
    disabled: false,
    searchBy: 'name',
    searchAsset: 'apps',
    limit: 10,
    autoFocus: true,
    minLength: 1,
    defaultNoResultsMessage: 'No results found.',
    clearButton: false,
    disableSearchOnFocus: false,
    disableRecentResults: false,
    cancelRequestsAfterSelect: false,
    requestOptions: {},
    includesUnified: false,
    decorateItems: items => items,
    onBeforeSearchCallback: null,
    decorators: [],
    typeaheadId: 'search-typeahead',
    maxHeight: '300px',
    defaultSearchQuery: '/search',
    menuStyles: {},
    AppItemProps: {},
    PublisherItemProps: {},
    KeywordItemProps: {},
    labelKey: 'value',
    onPageChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      query: '',
      groupedResults: {},
      searchResults: props.defaultSearchResults || [],
      notFound: false,
      isLoading: false,
      errorOccurred: false,
      offset: defaultOffsets(props.assets),
    };

    this.cancelSource = null;
    this.renderItem = props.renderMenuItem ? props.renderMenuItem : this.defaultRenderItem;
    this.decorateItem = props.itemSelected ? this.decorateSearchItem : this.defaultDecorate;
    this.isOneAsset = !props.assets || props.assets.length === 1;
  }

  componentDidMount() {
    const inputEl = this.typeaheadInstance().getInput();
    if (inputEl.getAttribute('autocomplete') !== 'off') {
      inputEl.setAttribute('autocomplete', 'off');
    }
  }

  componentDidUpdate(prevProps) {
    const isSearchByChanged = prevProps.searchBy !== this.props.searchBy;
    const isReSearchFlagChanged = prevProps.reSearchFlag !== this.props.reSearchFlag;

    if (isSearchByChanged || isReSearchFlagChanged) {
      this.handleSearch(this.state.query);
    }
    if (prevProps.store !== this.props.store && this.props.searchAsset !== 'keywords') {
      // eslint-disable-next-line
      this.setState({
        offset: defaultOffsets(this.props.assets),
      }, () => {
        this.handleSearch(this.state.query);
      });
    }
  }

  componentWillUnmount() {
    this.cancelRequest();
  }

  get errorsMessages() {
    return {
      ...errorsMessages,
      ...(this.props.errorsMessages || {}),
    };
  }

  typeaheadInstance = () => this.typeahead;

  focus = () => this.typeaheadInstance().focus();
  blur = () => this.typeaheadInstance().blur();
  clear = () => this.typeaheadInstance().clear();

  cancelRequest = () => {
    if (this.cancelSource) this.cancelSource.cancel();
  }

  searchEndpoint = (assetName) => {
    const { advancedRequestConfig, defaultSearchQuery } = this.props;
    return get(advancedRequestConfig, [assetName, 'endpoint']) || defaultSearchQuery;
  }

  searchApiRequest = (query, assetName) => axios.get(this.searchEndpoint(assetName), {
    params: this.requestParams(query, [assetName]),
    cancelToken: this.cancelSource.token,
  })

  emptySearchAssets = () => {
    const { assets, searchAsset, advancedRequestConfig } = this.props;

    if (this.isOneAsset) return [searchAsset];

    // Allow additional requests on an empty search
    const additionalAssets = reduce(advancedRequestConfig, (memo, assetConfig, assetName) => (
      assetConfig.allowEmptySearch ? [...memo, assetName] : memo
    ), []);

    return [assets.find(item => !additionalAssets.includes(item)), ...additionalAssets];
  }

  requestParams = (query, assets) => {
    const {
      limit, requestOptions, store, decorators,
      searchAsset, searchBy, includesUnified,
      advancedRequestConfig,
    } = this.props;

    const { offset } = this.state;
    const searchAppsByKeyword = searchAsset === 'apps' && searchBy === 'keyword';
    const advancedRequestParams = get(advancedRequestConfig, [assets[0], 'params'], {});
    const { options: advancedOptions, ...restAdvancedParams } = advancedRequestParams;

    return {
      term: query,
      options: {
        limit,
        offset: offset[searchAsset],
        assets,
        ...requestOptions,
        ...(store ? { store } : {}),
        ...(decorators.length > 0 ? { decorators } : {}),
        ...(includesUnified ? { includes_unified: includesUnified } : {}),
        ...(advancedOptions || {}),
      },
      ...restAdvancedParams,
      ...(searchAppsByKeyword ? { by: searchBy } : {}),
    };
  }

  handleInfinitScroll = (offset) => {
    const { searchAsset } = this.props;
    this.setState({
      offset: { ...this.state.offset, ...{ [searchAsset]: offset } },
    }, () => {
      this.handleSearch(this.state.query);
    });
  }

  handleScroll = ({ offset }) => {
    const { searchAsset } = this.props;
    this.setState({
      offset: { ...this.state.offset, ...{ [searchAsset]: offset } },
    }, () => {
      this.handleSearchBy(this.state.query, this.props.searchAsset);
    });
  }

  handleSearch = (searchQuery) => {
    let query = searchQuery.trim();

    const {
      disableSearchOnFocus, disableRecentResults, decorateItems,
      defaultSearchResults, searchAsset, assets,
      onBeforeSearchCallback, labelKey,
    } = this.props;

    this.cancelRequest();
    this.cancelSource = axios.CancelToken.source();

    if (query === '' && disableSearchOnFocus) {
      this.setState({
        query,
        ...(disableRecentResults && {
          searchResults: defaultSearchResults || [],
          groupedResults: {},
          isLoading: false,
        }),
      }, () => {
        this.handleSearchCallback({ searchResults: [], groupedResults: {}, isLoading: false, totalCount: 0 });
      });
      return;
    }

    if (onBeforeSearchCallback) {
      const updatedQuery = onBeforeSearchCallback(this.typeaheadInstance().state.text);

      this.typeaheadInstance().setState({ text: updatedQuery });
      query = updatedQuery;
    }

    this.setState({
      isLoading: true,
      searchResults: [],
      query,
      notFound: false,
      errorOccurred: false,
    }, () => {
      this.handleSearchCallback({ searchResults: [], groupedResults: GROUPED_RESULTS, isLoading: true, totalCount: 0 }, query);
      Promise.all([
        ...(query.length > 0
          ? [this.searchApiRequest(query, searchAsset)]
          : this.emptySearchAssets().map(assetName => this.searchApiRequest(query, assetName))
        ),
      ]).then(([{ data }, ...additionalResponse]) => {
        const additionalResults = additionalResponse.reduce((memo, response) => (
          memo.concat(response.data.results)
        ), []);
        const resultArr = (data.results || []).concat(additionalResults);
        const totalCount = data.total_count || data.total;
        const resultNotFound = isEmpty(resultArr) || !resultArr[0][labelKey];
        const result = {
          searchResults: resultNotFound ? [] : decorateItems(resultArr),
          isLoading: false,
          notFound: this.isOneAsset && resultNotFound,
          totalCount,
          groupedResults: {},
        };

        if (query !== '') {
          result.groupedResults = {
            ...GROUPED_RESULTS,
            [searchAsset]: {
              loading: false,
              data: result.searchResults,
              totalCount: result.totalCount,
            },
          };
          if (!this.isOneAsset) {
            const restAssets = assets.filter(asset => asset !== searchAsset);
            restAssets.forEach((kind) => {
              this.handleSearchBy(query, kind);
            });
          }
        }

        this.setState(result, () => {
          this.handleSearchCallback(result, query);
        });
      }).catch((error) => {
        if (!axios.isCancel(error)) {
          this.setState({
            errorOccurred: true,
            isLoading: false,
            searchResults: [],
            groupedResults: {},
          }, () => {
            this.handleSearchCallback({ searchResults: [], groupedResults: {}, isLoading: false, totalCount: 0 }, '', error.response && error.response.status === 403);
          });
        }
      });
    });
  };

  handleSearchBy(query, by) {
    const { decorateItems, searchAsset } = this.props;
    const { offset } = this.state;
    this.searchApiRequest(query, by).then(({ data }) => {
      const { searchResults, groupedResults } = this.state;
      const resultArr = data.results;
      const totalCount = data.total_count;
      const nextOffset = data.next_offset;
      const decorateResults = decorateItems(resultArr);
      const newAssetData = [...get(groupedResults, [by, 'data'], []), ...decorateResults];
      const result = {
        searchResults: [...searchResults, ...decorateResults],
        groupedResults: {
          ...groupedResults,
          [by]: {
            loading: false,
            data: newAssetData,
            totalCount,
          },
        },
        offset: { ...offset, ...{ [searchAsset]: nextOffset } },
      };
      this.setState(result, () => {
        this.handleSearchCallback(result, query);
      });
    }).catch((error) => {
      if (!axios.isCancel(error)) {
        console.log('An error occurred', error);
      }
    });
  }

  handleInputChange = (query) => {
    this.setState({
      offset: defaultOffsets(this.props.assets),
    });
    if (this.props.onInputChange) {
      this.props.onInputChange(query);
    }
    if (!query) {
      if (this.props.minLength === 0) {
        this.handleSearch('');
      } else {
        this.cancelRequest();
        this.setState({
          query: '',
          isLoading: false,
          searchResults: [],
          groupedResults: {},
        }, () => {
          this.blur();
          setTimeout(() => {
            this.focus();
            this.handleSearch('');
            this.handleSearchCallback({ searchResults: [], groupedResults: {}, isLoading: false, totalCount: 0 });
          }, 50);
        });
      }
    }
  };

  handleChange = (items) => {
    const { onChange, cancelRequestsAfterSelect } = this.props;
    if (cancelRequestsAfterSelect) this.cancelRequest();
    onChange(items, this.state.query);
  }

  handleFocus = (e) => {
    const { query, searchResults } = this.state;
    const { minLength, onFocus, decorateResultsOnFocus } = this.props;

    if (onFocus) onFocus(e);

    if (decorateResultsOnFocus) {
      this.setState({ searchResults: decorateResultsOnFocus(searchResults) });
      return;
    }

    if (minLength !== 0) return;

    if (e.type === 'focus' && !query.length && !searchResults.length) {
      this.handleSearch('');
    }
  };

  handleSearchCallback = (result = {}, query = '', forbidden = false) => {
    if (this.props.onSearchCallback) {
      this.props.onSearchCallback(result, query, forbidden);
    }
  };

  handleClear = () => {
    this.clear();
    this.focus();
    this.handleInputChange();
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) onBlur();
  };

  handleMenuToggle = (e) => {
    const { onMenuToggle } = this.props;
    if (onMenuToggle) onMenuToggle(e);
  };

  handleEnterDown = ({ key }) => {
    if (key !== 'Enter') return;

    const { onEnterDownCallback } = this.props;
    const inputValue = this.typeaheadInstance().getInput().value;
    if (onEnterDownCallback) onEnterDownCallback(inputValue);
  };

  defaultDecorate = item => item;

  decorateSearchItem = (item) => {
    const enhancedItem = this.props.itemSelected(item);
    return {
      ...item,
      ...enhancedItem,
      added: enhancedItem.itemSelected,
    };
  }

  defaultRenderItem = (item) => {
    const {
      searchAsset, onReplaceClick, AppItemProps, PublisherItemProps, KeywordItemProps,
    } = this.props;
    switch (searchAsset) {
      case 'apps': {
        const { component, ...restAppItemProps } = AppItemProps;
        const AppComponent = component || AppItem;

        return (
          <AppComponent
            app={item}
            {...restAppItemProps}
            onReplaceClick={onReplaceClick}
          />
        );
      }
      case 'publishers': {
        const { component, ...restPublisherItemProps } = PublisherItemProps;
        const PublisherComponent = component || PublisherItem;

        return (
          <PublisherComponent
            publisher={item}
            {...restPublisherItemProps}
            onReplaceClick={onReplaceClick}
          />
        );
      }
      case 'companies':
        return <CompanyItem company={item} onReplaceClick={onReplaceClick} />;
      case 'sdks':
        return <SdkItem sdk={item} />;
      case 'keywords':
        return <KeywordItem keyword={item} {...KeywordItemProps} />;
      case 'markets':
        return <MarketItem market={item} />;
      case 'reports':
        return <ReportItem report={item} />;
      default:
        return <DefaultItem item={item} />;
    }
  }

  filteredItems = (items) => {
    const { searchAsset } = this.props;
    const kinds = assetMapping[searchAsset];
    return items.filter(item => includes(kinds, item.kind));
  }

  isAssetEmpty = data => !this.filteredItems(data).length;
  isRecentDisabled = () => this.state.query === '' && this.props.disableRecentResults;

  renderMenuItems = items => (this.props.renderCustomMenuItems
    ? (
      this.props.renderCustomMenuItems({
        items,
        renderItem: this.renderItem,
        selectedAsset: this.props.searchAsset,
      })
    ) : (
      items.map((item, index) => (
        <MenuItem
          key={item.ref_no || item.id || index}
          position={index}
          option={item}
          disabled={item.added}
        >
          {this.renderItem(item)}
        </MenuItem>
      ))
    ));

  renderMenu = (items, menuProps) => {
    const {
      menuHeader, menuFooter, defaultNoResultsMessage, searchAsset,
      defaultSearchResults, defaultSearchResultsHeader, menuStyles,
      customMenuFooter,
    } = this.props;

    const {
      query, isLoading, notFound, errorOccurred,
      searchResults,
    } = this.state;

    const hasCustomRender = menuFooter || menuHeader || defaultSearchResultsHeader;
    const showMenuHeader = menuHeader && (!isEmpty(items) || !this.isOneAsset);
    const showNotfound = notFound || (!this.isOneAsset && this.isAssetEmpty(searchResults));

    const showDefaultSearchResultsHeader = defaultSearchResultsHeader
      && !!(defaultSearchResults || []).length
      && (!showNotfound && !errorOccurred && !isLoading)
      && uniqBy([...defaultSearchResults, ...searchResults], 'ref_no').length === defaultSearchResults.length;

    const preparedItems = this.filteredItems(items).map(this.decorateItem);
    const itemToReplace = preparedItems.find(item => item.showReplaceButton);
    let conflictEntity = { present: false };

    if (itemToReplace) {
      const { conflicts } = itemToReplace;
      conflictEntity = {
        present: true,
        message: (conflicts && conflicts.length)
          ? <EntityConflictMessages enhancedEntities={preparedItems} />
          : (
            <span className="d-block p-l-sm text-12 text-left text-error pb-xxs">
              {this.errorsMessages[itemToReplace.kind !== 'unified_app' ? 'entitiesPresentStore' : 'entitiesPresent']}
            </span>
          ),
      };
    }

    if (!hasCustomRender || isLoading) {
      return (
        <Menu {...menuProps} {...{ style: { ...menuStyles, ...menuProps.style } }}>
          {/* Show loading state with a header when more than one asset is */}
          {this.isOneAsset && searchAsset !== 'reports' ? (
            <Fragment>
              {isLoading &&
              <Fragment>
                <li>{menuHeader}</li>
                <li className="disabled">
                  <div className="d-flex justify-content-center align-items-center bg-white pb-xs" style={{ height: 100 }}>
                    <span>Searching...</span>
                  </div>
                </li>
              </Fragment>
              }
              {!this.isRecentDisabled() && showNotfound && !errorOccurred &&
                <NotFoundState
                  text={(query.length === 0 || searchAsset === 'keywords')
                  ? defaultNoResultsMessage
                  : 'No results found.'}
                  disabled={searchAsset !== 'keywords'}
                />
              }
              {this.renderMenuItems(preparedItems)}
              {customMenuFooter && customMenuFooter}
            </Fragment>
          ) : (
            <Fragment>
              <li>{menuHeader}</li>
              <li className="disabled">
                <div className="d-flex justify-content-center align-items-center bg-white pb-xs" style={{ height: 100 }}>
                  <span>Searching...</span>
                </div>
              </li>
            </Fragment>
          ) }
        </Menu>
      );
    }

    return (
      <Menu {...menuProps}>
        {!defaultSearchResultsHeader && !menuHeader && !isEmpty(items) &&
          <p>No results found.</p>
        }
        {!this.isRecentDisabled() && showMenuHeader && <li>{menuHeader}</li>}
        {showDefaultSearchResultsHeader && <li className="default-search-results-header">{defaultSearchResultsHeader}</li>}
        {!this.isRecentDisabled() && showNotfound && !errorOccurred &&
          <NotFoundState
            text={(query.length === 0 || searchAsset === 'keywords')
             ? defaultNoResultsMessage
             : 'No results found.'}
            disabled={searchAsset !== 'keywords'}
          />
        }
        {errorOccurred && <ErrorMessage />}
        {conflictEntity.message}
        {this.renderMenuItems(preparedItems)}
        {menuFooter && <li>{menuFooter}</li>}
        {customMenuFooter && customMenuFooter}
      </Menu>
    );
  }

  render() {
    const {
      query, isLoading, searchResults, notFound,
    } = this.state;

    const {
      className, placeholder, disabled,
      autoFocus, minLength, clearButton,
      bsSize, disableSearchOnFocus,
      maxHeight, open, labelKey,
    } = this.props;

    return (
      <div className="pos-rel">
        {clearButton && query.length > 0 &&
          <i
            className="pos-abs typeahead-close hicon-close input-clear"
            onClick={this.handleClear}
          />
        }
        <AsyncTypeahead
          id={this.props.typeaheadId}
          isLoading={isLoading}
          options={searchResults}
          filterBy={() => true}
          placeholder={placeholder}
          size={bsSize || null}
          className={cx(className, { 'not-found': notFound })}
          minLength={minLength}
          autoFocus={autoFocus}
          useCache={false}
          promptText={!disableSearchOnFocus && loadingSpinner}
          delay={400}
          multiple={false}
          labelKey={labelKey}
          ref={(ref) => {
            this.typeahead = ref;
          }}
          renderMenu={this.renderMenu}
          disabled={disabled}
          searchText={loadingSpinner}
          maxHeight={maxHeight}
          open={open}
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onMenuToggle={this.handleMenuToggle}
          onKeyDown={this.handleEnterDown}
        />
      </div>
    );
  }
}

export default SearchTypeahead;
