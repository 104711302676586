import React, { Fragment, useState } from 'react';
import T from 'prop-types';
import cx from 'classnames';

import NewFeatureRequestButton, { ableToProceed } from '../NewFeatureRequestButton';


const FinanceMenuFooter = ({ asset }) => {
  const featureName = 'Finance Sectors Upgrade';
  const [allowedRequest, setAllowedRequest] = useState(ableToProceed(featureName));

  const onRequestSuccess = () => setAllowedRequest(false);

  return (
    <div className={cx('custom-footer-wrapper', { 'short-wrapper': !allowedRequest })}>
      <h1>{`Get access to more ${asset}?`}</h1>
      <div>
        <span>
          {allowedRequest
            ? 'You are currently only able to view apps that are part of your plan. If you would like to add additional app support please request an upgrade and your customer success manager will contact you.'
            : (
              <Fragment>
                We have received your request to add additional apps to your plan.If you have not heard from your customer success manager, please email us at
                <a href="mailto:success@apptopia.com">{' success@apptopia.com'}</a>
              </Fragment>
            )
          }
        </span>
        {allowedRequest && (
          <NewFeatureRequestButton
            featureName={featureName}
            withoutModal
            buttonClassName="hms-btn hms-btn--md hms-btn--primary"
            onRequestSuccess={onRequestSuccess}
          />
        )}
      </div>
    </div>
  );
};

FinanceMenuFooter.propTypes = {
  asset: T.string,
};

export default FinanceMenuFooter;
