import { createRoot } from 'react-dom/client';
import React from 'react';

import HeaderSearch from '../../assets/javascripts/react/components/HeaderSearch';

document.addEventListener('DOMContentLoaded', () => {
  const rootNode = document.getElementById('header-search');
  const root = createRoot(rootNode);
  const disabled = rootNode.dataset.disabled === 'true';
  const isFinanceCustomer = rootNode.dataset.isFinanceCustomer === 'true';
  const input = rootNode.getElementsByClassName('header-search')[0];
  root.render(
    <HeaderSearch
      availableAssets={JSON.parse(input.dataset.kinds)}
      userPresent={input.dataset.loggedUser === 'true'}
      isFinanceCustomer={isFinanceCustomer}
      minLength={parseInt(input.dataset.minLength, 10)}
      placeholder={input.getAttribute('placeholder')}
      disabled={disabled}
    />,
  );
});
