import React from 'react';
import T from 'prop-types';
import { capitalize, partition } from 'lodash';
import { MenuItem } from 'react-bootstrap-typeahead';

import NewFeatureRequestButton from '../NewFeatureRequestButton';

const shiftIndex = 100;

const FinanceMenuItems = ({
  items,
  selectedAsset,
  renderItem,
}) => {
  if (!items || !items.length) return null;

  const [availableItems, additionaItems] = partition(items, ({ available }) => !!available);

  return (
    <div className="items-wrapper">
      <div className="available-items">
        <h1>{capitalize(selectedAsset)} included in your plan</h1>
        {availableItems.length > 0
          ? (
            availableItems.map((item, index) => (
              <MenuItem
                key={index}
                position={index}
                option={item}
                disabled={item.added}
              >
                {renderItem(item)}
              </MenuItem>
            ))
          ) : (
            <div className="available-items-empty">
              Your plan does not include any {selectedAsset} from your search.
              If you need additional {selectedAsset}, you may{' '}
              <NewFeatureRequestButton
                featureName={`Finance ${selectedAsset}`}
                showErrorInTooltip
                buttonClassName="hms-btn request-btn btn-allow-access"
                buttonText="request an upgrade."
              />
            </div>
          )
        }
      </div>
      {additionaItems.length > 0 && (
        <div className="additional-items">
          <h1>Additional {capitalize(selectedAsset)}</h1>
          {additionaItems.map((item, index) => (
            <MenuItem
              key={shiftIndex + index}
              position={shiftIndex + index}
              option={item}
              className="disabled"
            >
              {renderItem({ ...item, added: true })}
            </MenuItem>
          ))}
        </div>
      )}
    </div>
  );
};

FinanceMenuItems.propTypes = {
  items: T.array,
  selectedAsset: T.string.isRequired,
  renderItem: T.func.isRequired,
};

export default FinanceMenuItems;

export const getFinanceMenuItemsList = ({ items, renderItem }) => {
  if (!items || !items.length) return null;

  const [availableItems, additionalItems] = partition(items, ({ available }) => available === true);

  return [
    ...availableItems.map((item, index) => (
      <MenuItem
        key={index}
        position={index}
        option={item}
        disabled={item.added}
      >
        {renderItem(item)}
      </MenuItem>
    )),
    ...additionalItems.map((item, index) => (
      <MenuItem
        key={shiftIndex + index}
        position={shiftIndex + index}
        option={item}
        className="additional-items"
      >
        {renderItem({ ...item, added: true })}
      </MenuItem>
    )),
  ];
};
