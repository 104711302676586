import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import helpers from '../../utils/helpers';
import { storeName } from './shared';
import ReplaceButton from './ReplaceButton';

const iconClass = (kind, store) => {
  switch (kind) {
    case 'publisher':
      return storeName(store);
    case 'unified_publisher':
      return 'hicon-publisher';
    case 'company':
      return 'hicon-company';
    default:
      return '';
  }
};

const PublisherItem = ({
  publisher, decorator, nameWidth, onReplaceClick,
}) => {
  const handleItemClick = (e) => {
    if (publisher.showReplaceButton) {
      e.preventDefault();
      e.stopPropagation();
      onReplaceClick(publisher);
    }
    return false;
  };
  return (
    <div
      key={publisher.ref_no}
      className={cx('tt-suggestion', { disabled: publisher.added, 'has-replace-btn': publisher.showReplaceButton })}
      onClick={e => handleItemClick(e)}
    >
      <div className="category-item publisher-item">
        <div className="d-flex align-items-center flex-grow text-truncate">
          <h4 className="d-flex m-b-0" style={{ width: nameWidth }}>
            <div className="d-flex align-items-center flex-grow" style={{ minWidth: 0 }}>
              <i className={`store-icon ${iconClass(publisher.kind, publisher.store)} mr-xxs`} />
              <span className="text-truncate">{publisher.name ? helpers.decodeEntities(publisher.name) : '(Name not available)'}</span>
            </div>
            {publisher.showReplaceButton && <ReplaceButton />}
          </h4>
          {decorator}
        </div>
      </div>
    </div>
  );
};

PublisherItem.propTypes = {
  publisher: PropTypes.shape({
    value: PropTypes.string.isRequired,
    store: PropTypes.string,
    name: PropTypes.string.isRequired,
    added: PropTypes.bool,
    kind: PropTypes.string,
    showReplaceButton: PropTypes.bool,
    ref_no: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  decorator: PropTypes.element,
  nameWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onReplaceClick: PropTypes.func,
};

PublisherItem.defaultProps = {
  nameWidth: '100%',
};

export default PublisherItem;
