import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from '../Tooltip';

const limitText = 'Your team has reached the monthly limit for adding new keywords. You may come back and add this keyword next month.';

const KeywordItem = ({
  keyword: {
    keyword_id, name, ref_no, added, not_requested_keyword, requests_limit_reached, is_valid_query = true,
  },
  addKeywordText = 'Add to list and start tracking',
  activeKeyword,
  isAddedKeyword,
  handleAddKeyword,
}) => (
  <Fragment>
    {!not_requested_keyword && (
    <div key={keyword_id} className={cx('tt-suggestion', { disabled: added })}>
      <div className={cx('category-item category-item-keyword', { active: activeKeyword === ref_no })}>
        <div className="d-flex aling-items-center text-truncate">
          <h4 className="m-b-0 d-flex align-items-center">{name}</h4>
        </div>
      </div>
    </div>
    )}
    {not_requested_keyword && (
    <div key={name} className={cx('tt-suggestion', { disabled: requests_limit_reached })}>
      {requests_limit_reached || is_valid_query
        ? (
          <div className="add-untracked">
            { !handleAddKeyword && (
              <div className="text-left">
                &quot;{name}&quot; is not yet tracked
              </div>
            )}
            {(!isAddedKeyword && (requests_limit_reached ? true : !added)) && (
              <Fragment>
                <Tooltip content={requests_limit_reached ? limitText : null}>
                  <div
                    className={cx('text-blue text-12 p-t-sm text-left l-h-xxs d-flex', { 'cursor-pointer': !added })}
                    style={{ pointerEvents: 'auto' }}
                    onClick={handleAddKeyword ? e => handleAddKeyword(name, e) : undefined}
                  >
                    <i className="hicon-plus-circle-thin mr-xs text-14" />
                    <span className="text-12 l-h-xs">{addKeywordText}</span>
                  </div>
                </Tooltip>
                <div className="b-b p-t-sm p-t-sm" />
              </Fragment>
            )}
          </div>
        ) : (
          <div className={cx('add-untracked', { 'cursor-pointer': !added })}>
            <div className="text-left">
              &quot;{name}&quot; is not a valid keyword
            </div>
          </div>
        )
      }
    </div>
    )}
  </Fragment>
);

KeywordItem.propTypes = {
  keyword: PropTypes.shape({
    keyword_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    added: PropTypes.bool,
    not_requested_keyword: PropTypes.bool,
    requests_limit_reached: PropTypes.bool,
    is_valid_query: PropTypes.bool,
    ref_no: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  addKeywordText: PropTypes.string,
  handleAddKeyword: PropTypes.func,
  activeKeyword: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAddedKeyword: PropTypes.bool,
};

export default KeywordItem;
