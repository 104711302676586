import axios, { isCancel as isCancelRequest } from 'axios';
import qs from 'qs';

const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
const csrfToken = csrfTokenElement ? csrfTokenElement.content : '';
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' });

const buildRequestCancelation = () => {
  const cancelTokens = {};

  return (tokenName, { sideCancelation = [] } = {}) => {
    [tokenName, ...sideCancelation].forEach((name) => {
      if (cancelTokens[name]) cancelTokens[name].cancel();
    });

    cancelTokens[tokenName] = axios.CancelToken.source();
    return cancelTokens[tokenName].token;
  };
};

export { csrfToken, isCancelRequest, buildRequestCancelation };

export default axios;
